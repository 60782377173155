/* tslint:disable */

declare var Object: any;
export interface TransactionInterface {
  "compagnie": string;
  "code_client"?: number;
  "code_boutique": string;
  "num_panier"?: number;
  "btq_panier": string;
  "nom": string;
  "date_creation": Date;
  "date_modification": Date;
  "type_livraison": string;
  "montant_remise"?: number;
  "lib_remise"?: string;
  "item_ligne": number;
  "item_statut": string;
  "item_reference": string;
  "item_pointure"?: string;
  "item_quantite": number;
  "item_montant": number;
  "item_remise"?: number;
  "item_motif_remise"?: string;
  "motif_retour"?: string;
  "origine_id"?: number;
  "avoir"?: number;
  "transmis": number;
  "token"?: string;
  "id"?: number;
}

export class Transaction implements TransactionInterface {
  "compagnie": string;
  "code_client": number;
  "code_boutique": string;
  "num_panier": number;
  "btq_panier": string;
  "nom": string;
  "date_creation": Date;
  "date_modification": Date;
  "type_livraison": string;
  "montant_remise": number;
  "lib_remise": string;
  "item_ligne": number;
  "item_statut": string;
  "item_reference": string;
  "item_pointure": string;
  "item_quantite": number;
  "item_montant": number;
  "item_remise": number;
  "item_motif_remise": string;
  "motif_retour": string;
  "origine_id": number;
  "avoir": number;
  "transmis": number;
  "token": string;
  "id": number;
  constructor(data?: TransactionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Transaction`.
   */
  public static getModelName() {
    return "Transaction";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Transaction for dynamic purposes.
  **/
  public static factory(data: TransactionInterface): Transaction{
    return new Transaction(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Transaction',
      plural: 'transactions',
      path: 'transactions',
      idName: 'id',
      properties: {
        "compagnie": {
          name: 'compagnie',
          type: 'string'
        },
        "code_client": {
          name: 'code_client',
          type: 'number'
        },
        "code_boutique": {
          name: 'code_boutique',
          type: 'string'
        },
        "num_panier": {
          name: 'num_panier',
          type: 'number'
        },
        "btq_panier": {
          name: 'btq_panier',
          type: 'string'
        },
        "nom": {
          name: 'nom',
          type: 'string'
        },
        "date_creation": {
          name: 'date_creation',
          type: 'Date'
        },
        "date_modification": {
          name: 'date_modification',
          type: 'Date'
        },
        "type_livraison": {
          name: 'type_livraison',
          type: 'string'
        },
        "montant_remise": {
          name: 'montant_remise',
          type: 'number'
        },
        "lib_remise": {
          name: 'lib_remise',
          type: 'string'
        },
        "item_ligne": {
          name: 'item_ligne',
          type: 'number'
        },
        "item_statut": {
          name: 'item_statut',
          type: 'string'
        },
        "item_reference": {
          name: 'item_reference',
          type: 'string'
        },
        "item_pointure": {
          name: 'item_pointure',
          type: 'string'
        },
        "item_quantite": {
          name: 'item_quantite',
          type: 'number'
        },
        "item_montant": {
          name: 'item_montant',
          type: 'number'
        },
        "item_remise": {
          name: 'item_remise',
          type: 'number'
        },
        "item_motif_remise": {
          name: 'item_motif_remise',
          type: 'string'
        },
        "motif_retour": {
          name: 'motif_retour',
          type: 'string'
        },
        "origine_id": {
          name: 'origine_id',
          type: 'number'
        },
        "avoir": {
          name: 'avoir',
          type: 'number'
        },
        "transmis": {
          name: 'transmis',
          type: 'number'
        },
        "token": {
          name: 'token',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
