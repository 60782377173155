/* tslint:disable */

declare var Object: any;
export interface EcommerceInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "date": Date;
  "nbreCommandes"?: number;
  "montantPaye"?: number;
  "nbreAvoirs"?: number;
  "montantAvoirs"?: number;
  "nbrePaniers"?: number;
  "nbreInscrits"?: number;
  "nbreFactures"?: number;
  "montantFactures"?: number;
  "id"?: number;
}

export class Ecommerce implements EcommerceInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "date": Date;
  "nbreCommandes": number;
  "montantPaye": number;
  "nbreAvoirs": number;
  "montantAvoirs": number;
  "nbrePaniers": number;
  "nbreInscrits": number;
  "nbreFactures": number;
  "montantFactures": number;
  "id": number;
  constructor(data?: EcommerceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Ecommerce`.
   */
  public static getModelName() {
    return "Ecommerce";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Ecommerce for dynamic purposes.
  **/
  public static factory(data: EcommerceInterface): Ecommerce{
    return new Ecommerce(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Ecommerce',
      plural: 'Ecommerces',
      path: 'Ecommerces',
      idName: 'id',
      properties: {
        "boutiqueId": {
          name: 'boutiqueId',
          type: 'number'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "day": {
          name: 'day',
          type: 'number'
        },
        "week": {
          name: 'week',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "nbreCommandes": {
          name: 'nbreCommandes',
          type: 'number'
        },
        "montantPaye": {
          name: 'montantPaye',
          type: 'number'
        },
        "nbreAvoirs": {
          name: 'nbreAvoirs',
          type: 'number'
        },
        "montantAvoirs": {
          name: 'montantAvoirs',
          type: 'number'
        },
        "nbrePaniers": {
          name: 'nbrePaniers',
          type: 'number'
        },
        "nbreInscrits": {
          name: 'nbreInscrits',
          type: 'number'
        },
        "nbreFactures": {
          name: 'nbreFactures',
          type: 'number'
        },
        "montantFactures": {
          name: 'montantFactures',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
