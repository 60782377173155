import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

import { Boutique } from '../../shared/sdk/models';
import { BoutiqueApi } from '../../shared/sdk/services';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit, OnDestroy {

  public dateForm: FormGroup;
  private subscriptions: Subscription[] = new Array<Subscription>();

  public productsListe: MatTableDataSource<any> = new MatTableDataSource<any>();
  public displayedColumns : any[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public subQuantites: Subscription;
  public url: string;
  public key: string;
  public dateFrom: Date;
  public marques: Boutique[];
  public selectedBoutique: any;
  public errorMessage: string;
  private csvData: any;
  private csvDataFirst: boolean;

  constructor(private http: HttpClient, private builder: FormBuilder, private btqapi: BoutiqueApi) { 
    this.subscriptions.push(this.btqapi.find({where:{id:{lt:6}}}).subscribe(
      (btqs: Boutique[]) => {
        this.marques = btqs;
        
        if(sessionStorage.getItem("selectedBoutique")) {
          this.selectedBoutique = sessionStorage.getItem("selectedBoutique");
        }else{
          this.selectedBoutique = btqs[0].id;
        }

        if(sessionStorage.getItem("dateFrom")) {
          this.dateFrom = new Date(sessionStorage.getItem("dateFrom"));
        }else {
          this.dateFrom = new Date();
          this.dateFrom.setDate(this.dateFrom.getDate() - 30);
        }
        setTimeout(()=>{this.setup()});
      }
    ));
  }  

  ngOnInit() {
  }

  setup() {
    this.ngOnDestroy();
    this.displayedColumns = ["nom","reference", "reference_fournisseur", "pointure","quantite","alertes_mail"];

    this.dateForm = new FormGroup(this.builder.group({
      dateFrom: [this.dateFrom.toISOString().split('T')[0]]
    }).controls, {updateOn: 'change'});

    this.getProducts();
  }

  getProducts() {    
    var self = this;
    this.csvDataFirst = true;

    var marque = this.marques.find(
      function(elem) {
        return elem.id == self.selectedBoutique;
      }
    );
    
    this.url = marque.url;
    this.key = marque.token;

    var quantiteUrl = this.url+"/modules/ras_expeditions/api.php?ws_key="+this.key+"&url=stocks&dateFrom="+this.dateFrom.toISOString().split('T')[0];
    this.subscriptions.push(this.http.get(quantiteUrl).subscribe(
        (res: any) => {
          this.errorMessage = null;
          this.productsListe = new MatTableDataSource(res);
          setTimeout(()=>{
            this.subscriptions.push(this.productsListe.connect().subscribe(
              (d) => {
                if(this.csvDataFirst) {
                  this.csvData = d; 
                  this.csvDataFirst = false;
                }
              }
            ));
            this.productsListe.sort = this.sort;
            this.productsListe.paginator = this.paginator;
          });
        },
        (e) => {
          this.errorMessage = e;
        }
      ));

  }

  onSubmitForm() {
    this.dateFrom = new Date(this.dateForm.get("dateFrom").value);
    sessionStorage.setItem("dateFrom", this.dateFrom.toString());
    this.getProducts();
  }

  onSelectBtq(id: any) {
    this.selectedBoutique = id;
    sessionStorage.setItem("selectedBoutique", this.selectedBoutique);
    this.getProducts();
  }

  applyFilter(filterValue: string) {
    this.productsListe.filter = filterValue.trim().toLowerCase();
  }

  exportCsv() {
    new Angular5Csv(this.csvData, 'Etat des stocks', {fieldSeparator: ';', showLabels: true, headers: ["Id produit","Id pointure","Nom","Référence","Référence pointure","Pointure","Quantité","Alertes mail"]});
  }

  ngOnDestroy() {
    if(this.subscriptions.length > 0) this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
