import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SDKBrowserModule } from './shared/sdk';

import { SharedModuleModule } from './modules/shared-module/shared-module.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, 
  MatButtonModule, 
  MatSidenavModule, 
  MatIconModule, 
  MatListModule, 
  MatMenuModule, 
  MatSelectModule, 
  MatInputModule, 
  MatRadioModule,
  MatCardModule, 
  MatDialogModule,
  MatTableModule, 
  MatSortModule, 
  MatPaginatorModule } from '@angular/material';
import { FlexLayoutModule } from "@angular/flex-layout";

import { HighchartsChartModule } from 'highcharts-angular';

import { NotFoundComponent } from './not-found/not-found.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './auth/login/login.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { LostPasswordComponent } from './auth/lost-password/lost-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HitparadeComponent, ProductDialog } from './components/hitparade/hitparade.component';
import { HitparaderetourComponent } from './components/hitparaderetour/hitparaderetour.component';
import { SyntheseComponent } from './components/synthese/synthese.component';
import { registerLocaleData } from '@angular/common';
import { GeorepartitionComponent } from './components/georepartition/georepartition.component';
import { StocksComponent } from './components/stocks/stocks.component';
import { HitparadesaisonComponent } from './components/hitparadesaison/hitparadesaison.component';
import { TranchedageComponent } from './components/tranchedage/tranchedage.component';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LayoutComponent,
    LoginComponent,
    ProfileComponent,
    LostPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    HitparadeComponent,
    HitparaderetourComponent,
    ProductDialog,
    SyntheseComponent,
    GeorepartitionComponent,
    StocksComponent,
    HitparadesaisonComponent,
    TranchedageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SDKBrowserModule.forRoot(),
    BrowserAnimationsModule,
    SharedModuleModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FlexLayoutModule,
    HighchartsChartModule,
    AppRoutingModule
  ],
  entryComponents: [ProductDialog],
  exports: [ ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'fr-FR'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
