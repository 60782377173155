/* tslint:disable */

declare var Object: any;
export interface ErrorlogInterface {
  "id_contact": number;
  "type": string;
  "compagnie": string;
  "code_client": number;
  "code_boutique": string;
  "sync_date": Date;
  "message": string;
  "statut": boolean;
  "id"?: number;
}

export class Errorlog implements ErrorlogInterface {
  "id_contact": number;
  "type": string;
  "compagnie": string;
  "code_client": number;
  "code_boutique": string;
  "sync_date": Date;
  "message": string;
  "statut": boolean;
  "id": number;
  constructor(data?: ErrorlogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Errorlog`.
   */
  public static getModelName() {
    return "Errorlog";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Errorlog for dynamic purposes.
  **/
  public static factory(data: ErrorlogInterface): Errorlog{
    return new Errorlog(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Errorlog',
      plural: 'errorlogs',
      path: 'errorlogs',
      idName: 'id',
      properties: {
        "id_contact": {
          name: 'id_contact',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "compagnie": {
          name: 'compagnie',
          type: 'string'
        },
        "code_client": {
          name: 'code_client',
          type: 'number'
        },
        "code_boutique": {
          name: 'code_boutique',
          type: 'string'
        },
        "sync_date": {
          name: 'sync_date',
          type: 'Date'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "statut": {
          name: 'statut',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
