/* tslint:disable */

declare var Object: any;
export interface NewsletterInterface {
  "transmis"?: boolean;
  "compagnie": string;
  "code_boutique": string;
  "code_client"?: number;
  "email"?: string;
  "code_marque": string;
  "lib_marque": string;
  "opt_mail": number;
  "opt_sms": number;
  "opt_lettre": number;
  "sexe": number;
  "prefs"?: string;
  "langue"?: string;
  "date_upd": Date;
  "moved"?: boolean;
  "id"?: number;
}

export class Newsletter implements NewsletterInterface {
  "transmis": boolean;
  "compagnie": string;
  "code_boutique": string;
  "code_client": number;
  "email": string;
  "code_marque": string;
  "lib_marque": string;
  "opt_mail": number;
  "opt_sms": number;
  "opt_lettre": number;
  "sexe": number;
  "prefs": string;
  "langue": string;
  "date_upd": Date;
  "moved": boolean;
  "id": number;
  constructor(data?: NewsletterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Newsletter`.
   */
  public static getModelName() {
    return "Newsletter";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Newsletter for dynamic purposes.
  **/
  public static factory(data: NewsletterInterface): Newsletter{
    return new Newsletter(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Newsletter',
      plural: 'newsletters',
      path: 'newsletters',
      idName: 'id',
      properties: {
        "transmis": {
          name: 'transmis',
          type: 'boolean'
        },
        "compagnie": {
          name: 'compagnie',
          type: 'string'
        },
        "code_boutique": {
          name: 'code_boutique',
          type: 'string'
        },
        "code_client": {
          name: 'code_client',
          type: 'number'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "code_marque": {
          name: 'code_marque',
          type: 'string'
        },
        "lib_marque": {
          name: 'lib_marque',
          type: 'string'
        },
        "opt_mail": {
          name: 'opt_mail',
          type: 'number'
        },
        "opt_sms": {
          name: 'opt_sms',
          type: 'number'
        },
        "opt_lettre": {
          name: 'opt_lettre',
          type: 'number'
        },
        "sexe": {
          name: 'sexe',
          type: 'number'
        },
        "prefs": {
          name: 'prefs',
          type: 'string'
        },
        "langue": {
          name: 'langue',
          type: 'string'
        },
        "date_upd": {
          name: 'date_upd',
          type: 'Date'
        },
        "moved": {
          name: 'moved',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
