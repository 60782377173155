import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';

import { User, SDKToken } from '../shared/sdk/models';
import { UserApi, LoopBackAuth } from '../shared/sdk/services';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private authSubject = new Subject<any>();
  private adminSubject = new BehaviorSubject<any>(false);

  private ra: Subscription;
  private li: Subscription;
  private lo: Subscription;

  constructor(private userApi: UserApi,
              @Inject(LoopBackAuth) protected lbauth: LoopBackAuth) {
    this.restoreUser();
    this.restoreAdmin();
  }

  getAdmin(): Observable<any> {
    return this.adminSubject.asObservable();
  }

  restoreAdmin(): void { 
    this.ra = this.userApi.getCurrent({include:'roles'}).subscribe(
      (role: any) => {
        role.roles.forEach(role => {
          if(role.name === 'adminRAS') {
            this.adminSubject.next(true);
          }
        });
      }
    );
  }

  getAuth(): Observable<any> {
    return this.authSubject.asObservable();
  }
  
  restoreUser(): void {
    const user: User = this.lbauth.getCurrentUserData();    
    this.authSubject.next((user !== null) ? user : new User());
  }

  login(user: User, callback?: any): void {  
    this.li = this.userApi.login(user).subscribe((token: SDKToken) => {
      this.lbauth.setToken(token);
      this.lbauth.setRememberMe(true);
      this.lbauth.save();
      const user: any = this.lbauth.getCurrentUserData();
      this.authSubject.next(user);
      this.restoreAdmin();
      if (callback) {
        callback(null, user);
      }
    }, (error: any) => {
      // Pour que ce message s'affiche, il faut changer le statusCode 401 par 409 dans /node_modules/loopback/common/models/user.js ligne 252
      if (callback) {
        callback(error.message, null);
      }
    }); 
  }

  public logout(callback?: any): void {
    try {
      this.lo = this.userApi.logout().subscribe();
    } catch (e) { }
    this.lbauth.clear();
    this.authSubject.next(new User());
    this.adminSubject.next(false);
    if (callback) {
      callback(null, true);
    }
  }

  isAuth(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        if(this.userApi.isAuthenticated()) {
          resolve(true);
        }else{
          resolve(false);
        }
      }
    );
  }

  isAdmin(): Observable<boolean> | Promise<boolean> | boolean {
    console.dir("isAdmin");
    const user: any = this.lbauth.getCurrentUserData();
    return new Promise(
      (resolve, reject) => {
        if(this.userApi.existsRoles(user.id, 1)) {
          resolve(true);
        }else{
          resolve(false);
        }
      }
    );
  }

  ngOnDestroy() {
    this.ra.unsubscribe();
    this.li.unsubscribe();
    this.lo.unsubscribe();
  }
}
