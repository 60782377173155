import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { PeriodeService } from '../../services/periode.service'; 
import { Periode } from '../../services/periode.model';
import { HitParadeApi } from '../../shared/sdk/services';

@Component({
  selector: 'app-hitparade',
  templateUrl: './hitparade.component.html',
  styleUrls: ['./hitparade.component.css']
})
export class HitparadeComponent implements OnInit, OnDestroy {

  // Variable pour la période d'observation
  private perSub: Subscription;
  private periode =  new Periode();

  public hitparade: MatTableDataSource<any> = new MatTableDataSource<any>();
  public displayedColumns : any[];

  public sortBy: boolean;
  public compTo: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private hpapi: HitParadeApi, private perser: PeriodeService, public dialog: MatDialog) {

    this.sortBy = false;
    this.compTo = false;
    
    this.perSub = this.perser.getPeriode().subscribe(
      (per: any) => {
        this.periode = per;
        if(per.selectedBtq !== null) this.setup(per.selectedBtq);
      }
    ); 
  
  }

  ngOnInit() {
  }

  setup(id: any) {
    this.displayedColumns = ['couleur','reference','nom','qte','caHT']; 
    
    this.hpapi.hitByYMD(this.periode.selectedBtq, this.periode.dateDu, this.periode.dateTo, (this.periode.groupBy === 'e.year') ? ['e.year'] : (this.periode.groupBy === 'e.month') ? ['e.year', 'e.month'] : (this.periode.groupBy === 'e.week') ? ['e.year','week(e.date)'] : ['e.year', 'e.month', 'e.day']).toPromise().then(
      (stats: {serie: any[]}) => {
        this.hitparade = new MatTableDataSource(stats.serie);
        setTimeout(()=>{this.hitparade.sort = this.sort;this.hitparade.paginator = this.paginator;})
      });
  }

  onGetProduct(filt: any) {
    const dialogRef = this.dialog.open(ProductDialog, {data: filt});
  }

  ngOnDestroy() {
    if(this.perSub)
      this.perSub.unsubscribe();

  }

}

@Component({
  selector: 'product-dialog',
  templateUrl: 'product-dialog.html',
  styleUrls: ['./hitparade.component.css']
})
export class ProductDialog {

  TAG = 'Prestashop API: ';
  public products$: Observable<any>;
  public stock$: Observable<any>;
  public lang$: Observable<any>;
  public url: string;
  public key: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient){

    var productUrl = data.url+"/api/products?ws_key="+data.key+"&output_format=JSON&display=full&filter[reference]=["+data.ref+"]";
    this.url = data.url;
    this.key = data.key;
    this.products$ = this.http.get(productUrl)
      .pipe(
        map(resp => {
          if(resp["products"]) {
            this.getStock(resp["products"][0].associations.stock_availables[0].id);
            this.getLang();
            return resp["products"]
          }else
            return [{"error": "Ce produit est introuvable, il a probablement changé de référence."}];
        }),
        catchError(this.handleError)
      );

  }

  getStock(id: any) {
    var stockUrl = this.url+"/api/stock_availables?ws_key="+this.key+"&output_format=JSON&display=full&filter[id]=["+id+"]";

    this.stock$ = this.http.get(stockUrl)
    .pipe(
      map(resp => resp["stock_availables"][0]['quantity']),
      catchError(this.handleError)
    );
  }

  getLang() {
    var langUrl = this.url+"/api/configurations?ws_key="+this.key+"&output_format=JSON&display=full&filter[name]=[PS_LANG_DEFAULT]";
    this.lang$ = this.http.get(langUrl)
    .pipe(
      map(resp => resp["configurations"][0].value),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(this.TAG + 'An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.dir(error);
      console.error(
        `${this.TAG} Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(`${this.TAG} Something bad happened; please try again later.`);
  }
}
