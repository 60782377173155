import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas'; 

import { Boutique } from '../../../shared/sdk/models';
import { BoutiqueApi } from '../../../shared/sdk/services';

import { PeriodeService } from '../../../services/periode.service';
import { Periode } from '../../../services/periode.model';

@Component({
  selector: 'app-periode-form',
  templateUrl: './periode-form.component.html',
  styleUrls: ['./periode-form.component.css']
})
export class PeriodeFormComponent implements OnInit, OnDestroy {

  // Variables pour formulaire
  public periodeForm: FormGroup;
  //private comparaisonForm: FormGroup;
  public listeBoutiques: Boutique[];
  public idBoutiques: any[];
  public btnWidth: number = 100;
  public errorMessage: string = '';
  public periode = new Periode();
  private btqSub: Subscription;
  private perSub: Subscription;

  @Input() sortBy: boolean;
  @Input() compTo: boolean;

  constructor(private btqapi: BoutiqueApi, private per: PeriodeService, private builder: FormBuilder) {
    
    if(!this.periodeForm) this.initForm();

    this.btqSub = this.per.getBoutiques().subscribe(
      (btqs: Boutique[]) => {
        this.listeBoutiques = btqs;
        if(!this.idBoutiques || this.idBoutiques.length === 0) {
          this.idBoutiques = new Array();
          btqs.forEach((el) => {
            this.idBoutiques.push(el.id);
          });
        }
        
        this.btnWidth = 100 / (btqs.length + 1);
      },
      (e) => { console.dir(e)},
      () => { console.dir("complete")}
    );
    
    this.perSub = this.per.getPeriode().subscribe(
      (periode: any) => {
        this.periode = periode;
        setTimeout(() => {
          if(periode.dateTo && periode.dateDu) {
            this.periodeForm.setValue({dateDu: periode.dateDu, dateTo: periode.dateTo, compDu: periode.compDu, compTo: periode.compTo});
          } 
        });
        //if(periode.compTo && periode.compDu) {
        //  this.periodeForm.setValue({compDu: periode.compDu, compTo: periode.compTo});
        //} 
      },
      (e) => { console.dir(e)},
      () => { console.dir("complete")}
    );
    
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.periodeForm = new FormGroup(this.builder.group({
      dateDu: [this.periode.dateDu, Validators.required],
      dateTo: [this.periode.dateTo, Validators.required],
      compDu: [this.periode.compDu],
      compTo: [this.periode.compTo]
    }).controls, {updateOn: 'change'});
  }

  onSelectBtq(id: any) {
    this.periode.selectedBtq = new Array();
    
    if(id) {
      if(!id.length)
        this.periode.selectedBtq.push(id);
      else
        this.periode.selectedBtq = id;
    }else{
      this.periode.selectedBtq = this.idBoutiques;
    }
    this.setPeriode();
  }

  onGroupBy(per: string) {
    if(per) { 
      this.periode.groupBy = per;
      this.setPeriode();
    }
  }

  onSubmitPeriode() {
    this.periode.dateDu = this.periodeForm.get("dateDu").value;
    this.periode.dateTo = this.periodeForm.get("dateTo").value;
    if((!this.periodeForm.get("compDu").value && this.periodeForm.get("compTo").value) || (this.periodeForm.get("compDu").value && !this.periodeForm.get("compTo").value)) {
      this.errorMessage = "Vous devez fournir deux dates (ou aucunes) pour la comparaison: début et fin";
    }else{
      this.errorMessage = "";
      this.periode.compDu = (this.periodeForm.get("compDu").value) ? this.periodeForm.get("compDu").value : null;
      this.periode.compTo = (this.periodeForm.get("compTo").value) ? this.periodeForm.get("compTo").value : null;
      this.setPeriode();
    }

  }

  onChangeDate(x: number, event: any) {
    if(this.periodeForm.get("compDu").value) {
      var dtd = new Date(this.periodeForm.get("dateDu").value);
      var dtt = x === 1 ?  new Date(event.target.value) : new Date(this.periodeForm.get("dateTo").value);
      var dif = Math.floor((Date.UTC(dtt.getFullYear(), dtt.getMonth(), dtt.getDate()) - Date.UTC(dtd.getFullYear(), dtd.getMonth(), dtd.getDate()) ) /(1000 * 60 * 60 * 24));
      var dtct = x === 1 ? new Date(this.periodeForm.get("compDu").value) : new Date(event.target.value);
      var dtcd = x === 1 ? new Date(this.periodeForm.get("compDu").value) : new Date(event.target.value);
      dtct.setDate(dtct.getDate() + dif);
      this.periodeForm.setValue({dateDu: dtd.toISOString().split('T')[0],dateTo: dtt.toISOString().split('T')[0],compDu: dtcd.toISOString().split('T')[0],compTo: dtct.toISOString().split('T')[0]});
    }
  }
/*
  onSubmitComparaison() {
    this.periode.compDu = (this.comparaisonForm.get("compDu").value) ? this.comparaisonForm.get("compDu").value : null;
    this.periode.compTo = (this.comparaisonForm.get("compTo").value) ? this.comparaisonForm.get("compTo").value : null;
    this.setPeriode();
  }
*/
  setPeriode() {
      this.per.setPeriode(this.periode);
  }

  onBack() {
    this.periodeForm.setValue({dateDu: this.periode.dateDu, dateTo: this.periode.dateTo, compDu: this.periode.compDu, compTo: this.periode.compTo});
  }

  onPrintScreen() {  
      var data = document.getElementById('dashboardContent');  
      html2canvas(data).then(canvas => {  
        // Few necessary setting options  
        var imgWidth = 208;   
        var pageHeight = 295;    
        var imgHeight = canvas.height * imgWidth / canvas.width;  
        var heightLeft = imgHeight;  
    
        const contentDataURL = canvas.toDataURL('image/png')  
        let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
        var position = 0;  
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, pageHeight)  
        pdf.save('WindyArtDashboard.pdf'); // Generated PDF   
      });  
  } 

  ngOnDestroy() {
    if(this.btqSub) this.btqSub.unsubscribe();
    if(this.perSub) this.perSub.unsubscribe();
  }

}
