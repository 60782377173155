/* tslint:disable */

declare var Object: any;
export interface ProduitInterface {
  "reference": string;
  "code_saison": string;
  "lib_saison": string;
  "code_collection": string;
  "lib_collection"?: string;
  "code_modele": string;
  "lib_modele"?: string;
  "code_matiere": string;
  "lib_matiere": string;
  "code_coloris"?: string;
  "lib_coloris": string;
  "code_coloris_conso1"?: string;
  "lib_coloris_conso1"?: string;
  "code_coloris_conso2"?: string;
  "lib_coloris_conso2"?: string;
  "code_famille"?: string;
  "lib_famille"?: string;
  "code_ss_famille"?: string;
  "lib_ss_famille"?: string;
  "code_type"?: string;
  "lib_type"?: string;
  "code_fonction"?: string;
  "lib_fonction"?: string;
  "code_ligne"?: string;
  "lib_ligne"?: string;
  "code_theme"?: string;
  "lib_theme"?: string;
  "code_gamme"?: string;
  "lib_gamme"?: string;
  "code_composition"?: string;
  "lib_composition"?: string;
  "sexe"?: string;
  "transmis": number;
  "id"?: number;
}

export class Produit implements ProduitInterface {
  "reference": string;
  "code_saison": string;
  "lib_saison": string;
  "code_collection": string;
  "lib_collection": string;
  "code_modele": string;
  "lib_modele": string;
  "code_matiere": string;
  "lib_matiere": string;
  "code_coloris": string;
  "lib_coloris": string;
  "code_coloris_conso1": string;
  "lib_coloris_conso1": string;
  "code_coloris_conso2": string;
  "lib_coloris_conso2": string;
  "code_famille": string;
  "lib_famille": string;
  "code_ss_famille": string;
  "lib_ss_famille": string;
  "code_type": string;
  "lib_type": string;
  "code_fonction": string;
  "lib_fonction": string;
  "code_ligne": string;
  "lib_ligne": string;
  "code_theme": string;
  "lib_theme": string;
  "code_gamme": string;
  "lib_gamme": string;
  "code_composition": string;
  "lib_composition": string;
  "sexe": string;
  "transmis": number;
  "id": number;
  constructor(data?: ProduitInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Produit`.
   */
  public static getModelName() {
    return "Produit";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Produit for dynamic purposes.
  **/
  public static factory(data: ProduitInterface): Produit{
    return new Produit(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Produit',
      plural: 'produits',
      path: 'produits',
      idName: 'id',
      properties: {
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "code_saison": {
          name: 'code_saison',
          type: 'string'
        },
        "lib_saison": {
          name: 'lib_saison',
          type: 'string'
        },
        "code_collection": {
          name: 'code_collection',
          type: 'string'
        },
        "lib_collection": {
          name: 'lib_collection',
          type: 'string'
        },
        "code_modele": {
          name: 'code_modele',
          type: 'string'
        },
        "lib_modele": {
          name: 'lib_modele',
          type: 'string'
        },
        "code_matiere": {
          name: 'code_matiere',
          type: 'string'
        },
        "lib_matiere": {
          name: 'lib_matiere',
          type: 'string'
        },
        "code_coloris": {
          name: 'code_coloris',
          type: 'string'
        },
        "lib_coloris": {
          name: 'lib_coloris',
          type: 'string'
        },
        "code_coloris_conso1": {
          name: 'code_coloris_conso1',
          type: 'string'
        },
        "lib_coloris_conso1": {
          name: 'lib_coloris_conso1',
          type: 'string'
        },
        "code_coloris_conso2": {
          name: 'code_coloris_conso2',
          type: 'string'
        },
        "lib_coloris_conso2": {
          name: 'lib_coloris_conso2',
          type: 'string'
        },
        "code_famille": {
          name: 'code_famille',
          type: 'string'
        },
        "lib_famille": {
          name: 'lib_famille',
          type: 'string'
        },
        "code_ss_famille": {
          name: 'code_ss_famille',
          type: 'string'
        },
        "lib_ss_famille": {
          name: 'lib_ss_famille',
          type: 'string'
        },
        "code_type": {
          name: 'code_type',
          type: 'string'
        },
        "lib_type": {
          name: 'lib_type',
          type: 'string'
        },
        "code_fonction": {
          name: 'code_fonction',
          type: 'string'
        },
        "lib_fonction": {
          name: 'lib_fonction',
          type: 'string'
        },
        "code_ligne": {
          name: 'code_ligne',
          type: 'string'
        },
        "lib_ligne": {
          name: 'lib_ligne',
          type: 'string'
        },
        "code_theme": {
          name: 'code_theme',
          type: 'string'
        },
        "lib_theme": {
          name: 'lib_theme',
          type: 'string'
        },
        "code_gamme": {
          name: 'code_gamme',
          type: 'string'
        },
        "lib_gamme": {
          name: 'lib_gamme',
          type: 'string'
        },
        "code_composition": {
          name: 'code_composition',
          type: 'string'
        },
        "lib_composition": {
          name: 'lib_composition',
          type: 'string'
        },
        "sexe": {
          name: 'sexe',
          type: 'string'
        },
        "transmis": {
          name: 'transmis',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
