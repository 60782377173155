/* tslint:disable */
import {
  Boutique
} from '../index';

declare var Object: any;
export interface TrafficInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "date": Date;
  "sessions"?: number;
  "users"?: number;
  "rebond"?: number;
  "newUsers"?: number;
  "source"?: string;
  "device"?: string;
  "adCost"?: number;
  "adsCost"?: number;
  "id"?: number;
  boutique?: Boutique;
}

export class Traffic implements TrafficInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "date": Date;
  "sessions": number;
  "users": number;
  "rebond": number;
  "newUsers": number;
  "source": string;
  "device": string;
  "adCost": number;
  "adsCost": number;
  "id": number;
  boutique: Boutique;
  constructor(data?: TrafficInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Traffic`.
   */
  public static getModelName() {
    return "Traffic";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Traffic for dynamic purposes.
  **/
  public static factory(data: TrafficInterface): Traffic{
    return new Traffic(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Traffic',
      plural: 'Traffics',
      path: 'Traffics',
      idName: 'id',
      properties: {
        "boutiqueId": {
          name: 'boutiqueId',
          type: 'number'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "day": {
          name: 'day',
          type: 'number'
        },
        "week": {
          name: 'week',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "sessions": {
          name: 'sessions',
          type: 'number'
        },
        "users": {
          name: 'users',
          type: 'number'
        },
        "rebond": {
          name: 'rebond',
          type: 'number'
        },
        "newUsers": {
          name: 'newUsers',
          type: 'number'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "device": {
          name: 'device',
          type: 'string'
        },
        "adCost": {
          name: 'adCost',
          type: 'number'
        },
        "adsCost": {
          name: 'adsCost',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        boutique: {
          name: 'boutique',
          type: 'Boutique',
          model: 'Boutique',
          relationType: 'belongsTo',
                  keyFrom: 'boutiqueId',
          keyTo: 'id'
        },
      }
    }
  }
}
