/* tslint:disable */
import {
  Pole,
  Societe,
  Traffic,
  Ecommerce
} from '../index';

declare var Object: any;
export interface BoutiqueInterface {
  "code": string;
  "nom": string;
  "type_boutique"?: string;
  "compagnie"?: string;
  "active": number;
  "googleID"?: number;
  "facebookID"?: number;
  "newsAPIKey"?: string;
  "couleur"?: string;
  "societeId": number;
  "poleId": number;
  "url"?: string;
  "token"?: string;
  "id"?: number;
  pole?: Pole;
  societe?: Societe;
  traffic?: Traffic[];
  ecommerce?: Ecommerce[];
}

export class Boutique implements BoutiqueInterface {
  "code": string;
  "nom": string;
  "type_boutique": string;
  "compagnie": string;
  "active": number;
  "googleID": number;
  "facebookID": number;
  "newsAPIKey": string;
  "couleur": string;
  "societeId": number;
  "poleId": number;
  "url": string;
  "token": string;
  "id": number;
  pole: Pole;
  societe: Societe;
  traffic: Traffic[];
  ecommerce: Ecommerce[];
  constructor(data?: BoutiqueInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Boutique`.
   */
  public static getModelName() {
    return "Boutique";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Boutique for dynamic purposes.
  **/
  public static factory(data: BoutiqueInterface): Boutique{
    return new Boutique(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Boutique',
      plural: 'Boutiques',
      path: 'Boutiques',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "nom": {
          name: 'nom',
          type: 'string'
        },
        "type_boutique": {
          name: 'type_boutique',
          type: 'string'
        },
        "compagnie": {
          name: 'compagnie',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'number'
        },
        "googleID": {
          name: 'googleID',
          type: 'number'
        },
        "facebookID": {
          name: 'facebookID',
          type: 'number'
        },
        "newsAPIKey": {
          name: 'newsAPIKey',
          type: 'string'
        },
        "couleur": {
          name: 'couleur',
          type: 'string'
        },
        "societeId": {
          name: 'societeId',
          type: 'number'
        },
        "poleId": {
          name: 'poleId',
          type: 'number'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "token": {
          name: 'token',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        pole: {
          name: 'pole',
          type: 'Pole',
          model: 'Pole',
          relationType: 'belongsTo',
                  keyFrom: 'poleId',
          keyTo: 'id'
        },
        societe: {
          name: 'societe',
          type: 'Societe',
          model: 'Societe',
          relationType: 'belongsTo',
                  keyFrom: 'societeId',
          keyTo: 'id'
        },
        traffic: {
          name: 'traffic',
          type: 'Traffic[]',
          model: 'Traffic',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'boutiqueId'
        },
        ecommerce: {
          name: 'ecommerce',
          type: 'Ecommerce[]',
          model: 'Ecommerce',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'boutiqueId'
        },
      }
    }
  }
}
