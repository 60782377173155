import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms'; 
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

import { ProduitApi } from '../../shared/sdk/services';

import { TransactionApi } from '../../shared/sdk/services';

import { ProductService } from '../../services/product.service';
import { Product } from '../../services/product.model';

@Component({
  selector: 'app-tranchedage',
  templateUrl: './tranchedage.component.html',
  styleUrls: ['./tranchedage.component.css']
})
export class TranchedageComponent implements OnInit, OnDestroy {

  public tdaForm: FormGroup;
  public listeSaisons: any[];
  public listeCollections: any[];
  public listeModeles: any[];
  public listeMatieres: any[];
  public listeColoris: any[];
  public filtreForm: any;

  public listeRtda: MatTableDataSource<any> = new MatTableDataSource<any>();
  public listeHtda: MatTableDataSource<any> = new MatTableDataSource<any>();
  public displayedColumnsR : any[];
  public displayedColumnsH : any[];

  private product = new Product();
  private prodSub: Subscription;
  private rtaSub: Subscription;
  private htaSub: Subscription;

  public choixListe: string = 'rpta';
  public listeAges: number[] = [15,20,25,30,35,40,45,50,55,60,65,70,75];
  public infoForm: string;
  public titreTablo: string;

  @ViewChild(MatSort) sortR: MatSort;
  @ViewChild(MatPaginator) paginatorR: MatPaginator;
  @ViewChild(MatSort) sortH: MatSort;
  @ViewChild(MatPaginator) paginatorH: MatPaginator;

  constructor(private papi: ProduitApi, private tapi: TransactionApi, private builder: FormBuilder, private prod: ProductService) {

    this.prodSub = this.prod.getProduct().subscribe(
      (product: any) => {
        this.product = product;
        this.choixListe = product.liste ? product.liste : 'rpta';
        sessionStorage.setItem("product",JSON.stringify(product));
        setTimeout(() => {
            this.tdaForm.setValue(
              {
                selectSaison: product.saison ? product.saison : "0", 
                selectCollection: product.collection ? product.collection : "0", 
                selectModele: product.modele ? product.modele : "0", 
                selectMatiere: product.matiere ? product.matiere : "0", 
                selectColoris: product.coloris ? product.coloris : "0",
                selectTransmis: product.transmis ? product.transmis : "X",
                selectAgeMin: product.ageMin ? product.ageMin : 0,
                selectAgeMax: product.ageMax ? product.ageMax : 999,
                choixListe: product.liste ? product.liste : "rpta"                
              });
              this.initFilters();
        });
      },
      (e) => { console.dir(e) },
      () => { console.dir("complete") }
    );

   }

  ngOnInit() {
    this.displayedColumnsR = ["tranchedage", "nbre","pourcentage"];
    this.displayedColumnsH = ["reference","modele","nbre"];
    this.initForm();
    if(this.choixListe === 'rpta')
      this.initListeR();
    else
      this.initListeH();
  }

  ngOnDestroy() {
    if(this.prodSub) this.prodSub.unsubscribe();
    if(this.rtaSub) this.rtaSub.unsubscribe();
    if(this.htaSub) this.htaSub.unsubscribe();
  }

  initFilters() {
    var tempFilter: any[] = [];
    this.filtreForm = {};

    if(this.product.saison)
      tempFilter.push({code_saison: this.product.saison});
    
    if(this.product.collection)
      tempFilter.push({code_collection: this.product.collection});

    if(this.product.modele)
      tempFilter.push({code_modele: this.product.modele});

    if(this.product.matiere)
      tempFilter.push({code_matiere: this.product.matiere});

    if(this.product.coloris)
      tempFilter.push({code_coloris: this.product.coloris});
    
    if(this.product.transmis)
      tempFilter.push({transmis: this.product.transmis});

    this.filtreForm.where = { and: tempFilter };

    if(sessionStorage.getItem('listeSaisons')) {
      this.listeSaisons = JSON.parse(sessionStorage.getItem('listeSaisons'))
    }else {
      this.papi.distinct('code_saison,lib_saison', 1, '1').toPromise().then(
        (res: {list: any[]}) => {
          this.listeSaisons = res.list;
          sessionStorage.setItem("listeSaisons",JSON.stringify(res.list));
        }
      );
    }

    if(this.product.saison || this.tdaForm.get("selectSaison").value != "0") {
      var selectedSaison = this.tdaForm.get('selectSaison').value  != "0" ? this.tdaForm.get("selectSaison").value : this.product.saison;
      if(sessionStorage.getItem('COL'+selectedSaison)) {
        this.listeCollections = JSON.parse(sessionStorage.getItem('COL'+selectedSaison))
      }else {
        this.papi.distinct('code_collection,lib_collection', 2,'code_saison="'+selectedSaison+'"').toPromise().then(
          (res: {list: any[]}) => {
            this.listeCollections = res.list;
            sessionStorage.setItem("COL"+selectedSaison,JSON.stringify(res.list));
          }
        );
      }
    }else{
      this.listeCollections = [];
    }

    if(this.product.saison && (this.product.collection || this.tdaForm.get("selectCollection").value != "0")) {
      var selectedCollection = this.tdaForm.get("selectCollection").value != "0" ? this.tdaForm.get("selectCollection").value : this.product.collection;
      if(sessionStorage.getItem("MOD"+this.product.saison+selectedCollection)) {
        this.listeModeles = JSON.parse(sessionStorage.getItem("MOD"+this.product.saison+selectedCollection));
      }else{
        this.papi.distinct('code_modele,lib_modele', 2,'code_saison="'+this.product.saison+'" and code_collection="'+selectedCollection+'"').toPromise().then(
          (res: {list: any[]}) => {
            this.listeModeles = res.list;
            sessionStorage.setItem("MOD"+this.product.saison+selectedCollection,JSON.stringify(res.list));
          }
        ); 
      }
    }else{
      this.listeModeles = [];
    }

    if(this.product.saison && this.product.collection && (this.product.modele || this.tdaForm.get("selectModele").value != "0")) {
      var selectedModele = this.tdaForm.get("selectModele").value != "0" ? this.tdaForm.get("selectModele").value : this.product.modele;
      if(sessionStorage.getItem("MAT"+this.product.saison+this.product.collection+selectedModele)) {
        this.listeMatieres = JSON.parse(sessionStorage.getItem("MAT"+this.product.saison+this.product.collection+selectedModele));
      }else{
        this.papi.distinct('code_matiere,lib_matiere', 2, 'code_saison="'+this.product.saison+'" and code_collection="'+this.product.collection+'" and code_modele="'+selectedModele+'"').toPromise().then(
          (res: {list: any[]}) => {
            this.listeMatieres = res.list;
            sessionStorage.setItem("MAT"+this.product.saison+this.product.collection+selectedModele,JSON.stringify(res.list));
          }
        );       
      }
    }else{
      this.listeMatieres = [];
    }

    if(this.product.saison && this.product.collection && this.product.modele && (this.product.matiere || this.tdaForm.get("selectMatiere").value != "0")) {
      var selectedMatiere = this.tdaForm.get("selectMatiere").value != "0" ? this.tdaForm.get("selectMatiere").value : this.product.matiere;
      if(sessionStorage.getItem("CLR"+this.product.saison+this.product.collection+this.product.modele+selectedMatiere)) {
        this.listeColoris = JSON.parse(sessionStorage.getItem("CLR"+this.product.saison+this.product.collection+this.product.modele+selectedMatiere));
      }else{
        this.papi.distinct('code_coloris,lib_coloris', 2,'code_saison="'+this.product.saison+'" and code_collection="'+this.product.collection+'" and code_modele="'+this.product.modele+'" and code_matiere="'+selectedMatiere+'"').toPromise().then(
          (res: {list: any[]}) => {
            this.listeColoris = res.list;
            sessionStorage.setItem("CLR"+this.product.saison+this.product.collection+this.product.modele+selectedMatiere,JSON.stringify(res.list));
          }
        ); 
      }
    }else{
      this.listeColoris = [];
    }
  }

  initForm() {
    // Les valeurs nulles sont mises à 0 pour éviter le bug #10675 (selectionChange fired 2 times on select option with null value)
    this.tdaForm = new FormGroup(this.builder.group({
      selectSaison: [this.product.saison ? this.product.saison : "0"],
      selectCollection: [this.product.collection ? this.product.collection : "0"],
      selectModele: [this.product.modele ? this.product.modele : "0"],
      selectMatiere: [this.product.matiere ? this.product.matiere : "0"],
      selectColoris: [this.product.coloris ? this.product.coloris : "0"],
      selectTransmis: [this.product.transmis ? this.product.transmis : "X"],
      selectAgeMin: [this.product.ageMin ? this.product.ageMin : 0],
      selectAgeMax: [this.product.ageMax ? this.product.ageMax : 999],
      choixListe: [this.product.liste ? this.product.liste : "rpta"]
    }).controls, {updateOn: 'change'});
  }

  initListeR() {

    if(!this.product.saison || !this.product.collection) {
      this.infoForm = "Vous devez sélectionner au minima une saison et une collection";
      this.listeRtda = new MatTableDataSource<any>();
      return false;
    }else{
      this.infoForm = '';
      this.titreTablo = "Répartition des ventes par tranche d'âge";
    }

    var refprod = this.product.saison+this.product.collection;
    if(this.product.modele) refprod += this.product.modele;
    if(this.product.matiere) refprod += this.product.matiere;
    if(this.product.coloris) refprod += this.product.coloris;
    
    if(this.rtaSub) this.rtaSub.unsubscribe();
    
    this.rtaSub = this.tapi.rpta(refprod).subscribe(
      (res: any) => {
        this.listeRtda = new MatTableDataSource(res.rta);
        this.listeRtda.sort = this.sortR;
        this.listeRtda.paginator = this.paginatorH;
        /*
        if(this.filtreListe)
          this.applyFilter(this.filtreListe);
        */
      }
    );}

  initListeH() {

    if(!this.product.saison || !this.product.collection || !this.product.ageMin || !this.product.ageMax) {
      this.infoForm = "Vous devez sélectionner au minima une saison, une collection, un âge mini. et un âge maxi.";
      this.listeHtda = new MatTableDataSource<any>();
      return false;
    }else{
      this.infoForm = '';
      this.titreTablo = "Hit parade des produits vendus pour les "+this.product.ageMin+"-"+this.product.ageMax+" ans";
    }

    var refprod = this.product.saison+this.product.collection;
    if(this.product.modele) refprod += this.product.modele;
    if(this.product.matiere) refprod += this.product.matiere;
    if(this.product.coloris) refprod += this.product.coloris;
    
    if(this.htaSub) this.htaSub.unsubscribe();

    this.htaSub = this.tapi.hpta(refprod, this.product.ageMin, this.product.ageMax).subscribe(
      (res: any) => {
        this.listeHtda = new MatTableDataSource(res.hta);  
        this.listeHtda.sort = this.sortH;
        this.listeHtda.paginator = this.paginatorH;
        /*
        if(this.filtreListe)
          this.applyFilter(this.filtreListe);
          
        this.totalListe = this.listeHtda.filteredData.length;
        */
      }
    );}

  onSubmitTdaForm(sel: any) {
    switch(sel) {
      case 'sa': if(this.tdaForm.get('selectSaison').value != "0") {
                  this.product.saison = this.tdaForm.get('selectSaison').value;
                  delete this.product.collection;
                  delete this.product.modele;
                  delete this.product.matiere;
                  delete this.product.coloris;
                }else
                  this.product = new Product();
                this.setProduct();
                break;
      case 'co': if(this.tdaForm.get('selectCollection').value != "0") {
                  this.product.collection = this.tdaForm.get('selectCollection').value;
                  delete this.product.modele;
                  delete this.product.matiere;
                  delete this.product.coloris;
                }else{
                  delete this.product.collection;
                  delete this.product.modele;
                  delete this.product.matiere;
                  delete this.product.coloris;
                }
                this.setProduct();
                break;
      case 'mo': if(this.tdaForm.get('selectModele').value != "0") {
                  this.product.modele = this.tdaForm.get('selectModele').value;
                  delete this.product.matiere;
                  delete this.product.coloris;
                }else{
                  delete this.product.modele;
                  delete this.product.matiere;
                  delete this.product.coloris;
                }
                this.setProduct();
                break;
      case 'ma': if(this.tdaForm.get('selectMatiere').value != "0") {
                  this.product.matiere = this.tdaForm.get('selectMatiere').value;
                  delete this.product.coloris;
                }else{
                  delete this.product.matiere;
                  delete this.product.coloris;
                }
                this.setProduct();
                break;
      case 'cl': if(this.tdaForm.get('selectColoris').value != "0")
                  this.product.coloris = this.tdaForm.get('selectColoris').value;
                else
                  delete this.product.coloris;
                this.setProduct();
                break;
      case 'ami': this.product.ageMin = this.tdaForm.get('selectAgeMin').value ? this.tdaForm.get('selectAgeMin').value : 0;
                this.setProduct();
                break;
      case 'ama': this.product.ageMax = this.tdaForm.get('selectAgeMax').value ? this.tdaForm.get('selectAgeMax').value : 999;
                this.setProduct();
                break;
      default: this.setProduct();
    }

  }

  onChangeListe(e: any) {
    this.product.liste = e.value;
    this.choixListe = e.value;
    this.setProduct();
  }

  onChangeAge(e: any) {
    console.dir(e);
  }

  setProduct() {
      this.prod.setProduct(this.product);
      if(this.choixListe === 'rpta')
        this.initListeR();
      else
        this.initListeH();
  }

  onReset() {
      this.product = new Product();
      this.product.liste = this.choixListe ? this.choixListe : 'rpta';
      this.setProduct();
  }

}
