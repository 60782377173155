/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface DroitInterface {
  "userId": number;
  "type": string;
  "idForeign": number;
  "id"?: number;
  user?: User;
}

export class Droit implements DroitInterface {
  "userId": number;
  "type": string;
  "idForeign": number;
  "id": number;
  user: User;
  constructor(data?: DroitInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Droit`.
   */
  public static getModelName() {
    return "Droit";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Droit for dynamic purposes.
  **/
  public static factory(data: DroitInterface): Droit{
    return new Droit(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Droit',
      plural: 'Droits',
      path: 'Droits',
      idName: 'id',
      properties: {
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "idForeign": {
          name: 'idForeign',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
