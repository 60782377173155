/* tslint:disable */

declare var Object: any;
export interface HitParaderetourInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "date": Date;
  "reference": string;
  "nom": string;
  "raison": string;
  "qte": number;
  "id"?: number;
}

export class HitParaderetour implements HitParaderetourInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "date": Date;
  "reference": string;
  "nom": string;
  "raison": string;
  "qte": number;
  "id": number;
  constructor(data?: HitParaderetourInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `HitParaderetour`.
   */
  public static getModelName() {
    return "HitParaderetour";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of HitParaderetour for dynamic purposes.
  **/
  public static factory(data: HitParaderetourInterface): HitParaderetour{
    return new HitParaderetour(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'HitParaderetour',
      plural: 'HitParaderetours',
      path: 'HitParaderetours',
      idName: 'id',
      properties: {
        "boutiqueId": {
          name: 'boutiqueId',
          type: 'number'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "day": {
          name: 'day',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "nom": {
          name: 'nom',
          type: 'string'
        },
        "raison": {
          name: 'raison',
          type: 'string'
        },
        "qte": {
          name: 'qte',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
