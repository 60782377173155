import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';

import { forEach } from 'async-foreach';

import { BoutiqueApi } from '../shared/sdk/services';
import { Boutique } from '../shared/sdk/models';
import { Periode } from './periode.model';

@Injectable({
  providedIn: 'root'
})
export class PeriodeService implements OnDestroy {

  private periodeSubject = new BehaviorSubject<any>({selectedBtq:null,groupBy:null,dateDu:null,dateTo:null,compDu:null,compTo:null});
  private boutiquesSubject = new BehaviorSubject<any>([]);
  private perSub: Subscription;
  private btqSub: Subscription;
  private periode = new Periode();

  public listeBoutiques: Boutique[];

  constructor(private btqapi: BoutiqueApi) {
    this.restoreAll();
  }

  getPeriode(): Observable<any> {
    return this.periodeSubject.asObservable();
  }

  getBoutiques(): Observable<any> {
    return this.boutiquesSubject.asObservable();
  }

  setPeriode(per: Periode): void {
    if(per.selectedBtq) this.periode.selectedBtq = per.selectedBtq;
    if(per.groupBy) this.periode.groupBy = per.groupBy;
    if(per.dateDu) this.periode.dateDu = per.dateDu;
    if(per.dateTo) this.periode.dateTo = per.dateTo;
    if(per.compDu) this.periode.compDu = per.compDu;
    if(per.compTo) this.periode.compTo = per.compTo;
    if(per.repartition) this.periode.repartition = per.repartition;
    sessionStorage.setItem("periode",JSON.stringify(per));
    this.periodeSubject.next(per);
  }

  restorePeriode(): void {
    if(sessionStorage.getItem('periode')) {
      this.periode = JSON.parse(sessionStorage.getItem('periode'));
    }else{
      let firstn = new Date();
      let lastn = new Date();
      let firstn1 = new Date();
      let lastn1 = new Date();

      firstn.setDate(firstn.getDate() - 8);
      lastn.setDate(lastn.getDate() - 1);
      firstn1.setFullYear(firstn1.getFullYear() - 1);
      lastn1.setFullYear(lastn1.getFullYear() - 1);
      firstn1.setDate(firstn1.getDate() - 8);
      lastn1.setDate(lastn1.getDate() - 1); 

      if(!this.periode.groupBy) this.periode.groupBy = 'e.day';
      if(!this.periode.dateDu) this.periode.dateDu = firstn.toISOString().split('T')[0];
      if(!this.periode.dateTo) this.periode.dateTo = lastn.toISOString().split('T')[0];
      if(!this.periode.compDu) this.periode.compDu = firstn1.toISOString().split('T')[0];
      if(!this.periode.compTo) this.periode.compTo = lastn1.toISOString().split('T')[0];
      if(!this.periode.repartition) this.periode.repartition = 'region';
    }

    this.periodeSubject.next((this.periode !== null) ? this.periode: new Periode());
  }
  
  restoreAll(): void {
    // TODO: filtre where pour Droits sur Pole / Société / Boutique
    this.perSub = this.btqapi.find({where:{id:{lt:6}}}).subscribe(
      (btqs: Boutique[]) => {
        this.listeBoutiques = btqs;
        if(!this.periode.selectedBtq) {
          this.periode.selectedBtq = new Array<any>();
          forEach(btqs, (el, index, arr) => {
            this.periode.selectedBtq.push(el.id);
          }, (ok, arr) => {
            this.restorePeriode();
          });
        }
        this.boutiquesSubject.next(btqs);
      }
    );
  }
  ngOnDestroy() {
    if(this.perSub) this.perSub.unsubscribe();
    if(this.btqSub) this.btqSub.unsubscribe();
  }
}
