import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Product } from './product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productSubject = new BehaviorSubject<any>([]);
  private product = new Product();

  constructor() { 
    this.restoreProduct();
  }

  getProduct(): Observable<any> {
    return this.productSubject.asObservable();
  }

  setProduct(prod: Product): void {
    //sessionStorage.removeItem("product");
    sessionStorage.setItem("product",JSON.stringify(prod));
    this.productSubject.next(prod);
  }

  restoreProduct() {
    if(sessionStorage.getItem('product')) {
      this.product = JSON.parse(sessionStorage.getItem('product'));
    }else{
      this.product = new Product();
    }
    this.productSubject.next(this.product);
  }
}
