import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { forEach } from 'async-foreach';

import { Boutique } from '../../shared/sdk/models';
import { TrafficApi, BoutiqueApi } from '../../shared/sdk/services';

import { PeriodeService } from '../../services/periode.service'; 
import { Periode } from '../../services/periode.model';

import * as Highcharts from 'highcharts';
// import * as data from 'highcharts/modules/data.src';exporting(Highcharts);
import * as highchartsMore from 'highcharts/highcharts-more.src';exporting(Highcharts);
import * as exporting from 'highcharts/modules/exporting.src';exporting(Highcharts);
import * as boostCanvas from 'highcharts/modules/boost-canvas.src'; boostCanvas(Highcharts);
import * as boost from 'highcharts/modules/boost.src';import { ok } from 'assert';
boost(Highcharts);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  // Variable pour la période d'observation
  private perSub: Subscription;
  private periode =  new Periode();
  private btqSub: Subscription;

  public sortBy: boolean;
  public compTo: boolean;

  // Variables de stats
  private btqs = new Array<any>(); // Les boutiques choisies
  public visiteurs: any[]; // Le nombre de visiteurs
  private sessions: any[]; // Le nombre de sessions
  private rebond: any[]; // Le taux de rebond
  public newVisiteurs: any[]; // Le nombre de nouveaux visiteurs
  public adCost: any[]; // Le cout de la pub adWords
  private tauxTransfo: any[]; // Le taux de transformation ( nbre commandes / sessions)
  private tauxRetour: any[]; // Le taux de retour (montant avoirs / montant commandes)
  private caTTC: any[]; // Le CA TTC
  private nbreAvoirs: any[]; // Le nombre d'avoirs (retours)
  private retoursTTC: any[]; // Le montant des avoirs (retours)
  private devices: { sessions: any[], rebond: any[] }; // Les différents devices
  private sources: { sessions: any[], rebond: any[] }; // Les différentes sources de traffic

  public totSessions: any[] = new Array();
  public totCA: any[] = new Array();
  public totTT: any[] = new Array();
  public totInscrits: any[] = new Array();
  public croissance: boolean;

  private histoCat: any[]; // Les catégories pour l'axe X des histogrammes

  private avgVis = new Array<any>(); // Moyenne de visiteurs
  private avgSes = new Array<any>(); // Moyenne de sessions
  private avgReb = new Array<any>(); // Moyenne du taux de rebond
  private avgNev = new Array<any>(); // Moyenne des nouveaux visiteurs
  private avgAdc = new Array<any>(); // Moyenne des couts Google
  private avgTT = new Array<any>(); // Moyenne du taux de transformation (nbre commandes / sessions)
  private avgTR = new Array<any>(); // Moyenne du taux de retour (montant Avoirs / montant Commandes)
  private avgCA = new Array<any>(); // Moyenne du CA TTC

  // Series pour les graphiques
  seriesVisiteurs: any[];
  seriesSessions: any[];
  seriesRebond: any[];
  seriesNewVisiteurs: any[];
  seriesAdCosts: any[];
  seriesTransformation: any[];
  seriesRetour: any[];
  seriesCA: any[];
  seriesDevice: any[];
  seriesSource: any[];

  // Graphiques
  HG = Highcharts;

  // Options pour les graphiques
  visOptions = {};// required
  sesOptions = {};// required
  rebOptions = {};// required
  nevOptions = {};// required
  adcOptions = {};// required
  ttransfoOptions = {};
  tretourOptions = {};
  caOptions = {};
  devSessionsOptions = {};
  devRebondOptions = {};
  souSessionsOptions = {};
  souRebondOptions = {};

  chartConstructor = 'chart'; // optional string, defaults to 'chart'
  chartCallback = function (chart: any) {} // optional function, defaults to null
  updateFlag = true; // optional boolean
  oneToOneFlag = true; // optional boolean, defaults to false

  constructor(private trafapi: TrafficApi, private btqapi: BoutiqueApi, private perser: PeriodeService) {

    this.sortBy = true;
    this.compTo = true;
    
    this.perSub = this.perser.getPeriode().subscribe(
      (per: any) => {
        this.periode = per;
        if(per.selectedBtq !== null) this.setup(per.selectedBtq);
      }
    );
    
    this.btqSub = this.perser.getBoutiques().subscribe(
      (list: Boutique[]) => {
        list.forEach(elem => {
          this.btqs.push(elem.id);
        })
      }
    );
    
  }

  ngOnInit() {
  }

  setup(id: any) {
    
    this.sessions = new Array();
    this.rebond = new Array();
    this.tauxTransfo = new Array();
    this.tauxRetour = new Array();
    this.caTTC = new Array();

    this.histoCat = new Array();

    this.seriesSessions = new Array<any>();
    this.seriesRebond = new Array<any>();
    this.seriesTransformation = new Array<any>();
    this.seriesRetour = new Array<any>();
    this.seriesCA = new Array<any>();
    this.seriesDevice = new Array<any>();
    this.seriesSource = new Array<any>();

    const self = this;
    let start1 = false;
    let start2 = false;

    if(id) {
      this.periode.selectedBtq = id;
    }else{
      this.periode.selectedBtq = this.btqs;
    }
    
    this.trafapi.dashboardByYMD(this.periode.selectedBtq, this.periode.dateDu, this.periode.dateTo, (this.periode.groupBy === 'e.year') ? ['e.year'] : (this.periode.groupBy === 'e.month') ? ['e.year', 'e.month'] : (this.periode.groupBy === 'e.week') ? ['e.year','week(e.date)'] : ['e.year', 'e.month', 'e.day']).toPromise().then(
      (stats: {serie: any[]}) => {
        if(stats.serie.length > 0) {
          self.sessions["N"] = new Array();
          self.sessions["AVG"] = new Array();
          self.rebond["N"] = new Array();
          self.rebond["AVG"] = new Array();
          self.tauxTransfo["N"] = new Array();
          self.tauxTransfo["AVG"] = new Array();
          self.tauxRetour["N"] = new Array();
          self.tauxRetour["AVG"] = new Array();
          self.caTTC["N"] = new Array();
          self.caTTC["AVG"] = new Array();

          self.totSessions["N"] = 0;
          self.totCA["N"] = 0;
          self.totTT["N"] = 0;
          self.totInscrits["N"] = 0;

          self.avgSes["N"] = 0;
          self.avgReb["N"] = 0;
          self.avgTT["N"] = 0;
          self.avgTR["N"] = 0;
          self.avgCA["N"] = 0;

          forEach(stats.serie, function(stat, index, arr){
            self.avgSes["N"] += (stat.sessions) ? Number(stat.sessions) : 0;
            self.avgReb["N"] += (stat.rebond) ? Number(stat.rebond) : 0;
            self.avgTT["N"] += (stat.tauxTT) ? Number(stat.tauxTT) : 0; //(Number(stat.caTTC) > 0 ? Number(stat.caTTC) : 0) / Number(stat.sessions);
            self.avgTR["N"] += (stat.tauxR) ? Number(stat.tauxR) : 0; // > 0 ? Number(stat.retoursTTC) : Number(stat.retoursTTC)*100) / (Number(stat.caTTC) > 0 ? Number(stat.caTTC) : 1);
            self.avgCA["N"] += (stat.caTTC) ? Number(stat.caTTC) : 0;

            self.totSessions["N"] += (stat.sessions) ? Number(stat.sessions) : 0;
            self.totCA["N"] += (stat.caTTC) ? Number(stat.caTTC.toFixed(0)) : 0;
            self.totTT["N"] += (stat.tauxTT) ? Number(stat.tauxTT.toFixed(2)) : 0;
            self.totInscrits["N"] += (stat.inscrits) ? Number(stat.inscrits.toFixed(0)) : 0;

            self.sessions["N"].push({x: new Date(stat.date), y: stat.sessions});
            self.sessions["AVG"].push({x: new Date(stat.date), y: 0});
            self.rebond["N"].push({x: new Date(stat.date), y: Number(stat.rebond.toFixed(2))});
            self.rebond["AVG"].push({x: new Date(stat.date), y: 0});
            self.tauxTransfo["N"].push({x: new Date(stat.date), y: (stat.tauxTT !== null) ? Number(stat.tauxTT.toFixed(2)) : 0}); //y: (Number(stat.caTTC) > 0 ? Number(stat.caTTC) : 0) /Number(stat.sessions)});
            self.tauxTransfo["AVG"].push({x: new Date(stat.date), y: 0});
            self.tauxRetour["N"].push({x: new Date(stat.date), y: (stat.tauxR !== null) ? Number(stat.tauxR.toFixed(2)): 0}); //caTTC)/Number(stat.sessions)});
            self.tauxRetour["AVG"].push({x: new Date(stat.date), y: 0});
            self.caTTC["N"].push( stat.caTTC !== null ? Number(stat.caTTC.toFixed(0)): 0);
            self.histoCat.push(self.periode.groupBy === 'e.year' ? new Date(stat.date).getFullYear() : self.periode.groupBy === 'e.month' ? new Date(stat.date).toLocaleString('fr-FR',{month: "long"}) : self.periode.groupBy === 'e.week' ? "Sem. "+Number(stat.week+1) : new Date(stat.date).toLocaleString('fr-FR', {day: '2-digit', month: 'long'}));
            //self.caTTC["AVG"].push({x: new Date(stat.caTTC), y: 0});
          }, (ok, arr) => {
              self.avgSes["N"] = self.avgSes["N"] / arr.length;
              self.avgReb["N"] = self.avgReb["N"] / arr.length;
              self.avgTT["N"] = self.avgTT["N"] / arr.length;
              self.avgTR["N"] = self.avgTR["N"] / arr.length;
              self.avgCA["N"] = self.avgCA["N"] / arr.length;
              self.totTT["N"] = self.totTT["N"] / arr.length;
              self.seriesSessions.push({color: "#1e25b7", name: new Date(this.periode.dateDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.dateTo).toLocaleDateString('fr-FR'), data: self.sessions["N"], boostThreshold: 1, turboThreshold: 1000});
              self.seriesSessions.push({color: "#7db5e6", name: "Moyenne N",  data: self.sessions["AVG"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgSes["N"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000});
              self.seriesRebond.push({color: "#1e25b7", name: new Date(this.periode.dateDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.dateTo).toLocaleDateString('fr-FR'), data: self.rebond["N"], boostThreshold: 1, turboThreshold: 1000});
              self.seriesRebond.push({color: "#7db5e6", name: "Moyenne N",  data: self.rebond["AVG"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgReb["N"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000});
              self.seriesTransformation.push({color: "#1e25b7", name: new Date(this.periode.dateDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.dateTo).toLocaleDateString('fr-FR'), data: self.tauxTransfo["N"], boostThreshold: 1, turboThreshold: 1000});
              self.seriesTransformation.push({color: "#7db5e6", name: "Moyenne N",  data: self.tauxTransfo["AVG"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgTT["N"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000});
              self.seriesRetour.push({color: "#1e25b7", name: new Date(this.periode.dateDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.dateTo).toLocaleDateString('fr-FR'), data: self.tauxRetour["N"], boostThreshold: 1, turboThreshold: 1000});
              self.seriesRetour.push({color: "#7db5e6", name: "Moyenne N",  data: self.tauxRetour["AVG"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgTR["N"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000});
              self.seriesCA.push({color: "#1e25b7", name: new Date(this.periode.dateDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.dateTo).toLocaleDateString('fr-FR'), data: self.caTTC["N"]});
              //self.seriesCA.push({color: "#7db5e6", name: "Moyenne N",  data: self.caTTC["AVG"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = self.avgCA["N"]; return rObj;}), boostThreshold: 1, turboThreshold: 1000});
              if(this.periode.compDu && this.periode.compTo) {

                this.trafapi.dashboardByYMD(this.periode.selectedBtq, this.periode.compDu, this.periode.compTo, (this.periode.groupBy === 'e.year') ? ['e.year'] : (this.periode.groupBy === 'e.month') ? ['e.year', 'e.month'] : (this.periode.groupBy === 'e.week') ? ['e.year','week(e.date)'] : ['e.year', 'e.month', 'e.day']).toPromise().then(
                  (stats: {serie: any[]}) => {
                    
                    self.sessions["N1"] = new Array();
                    self.sessions["AVG1"] = new Array();
                    self.rebond["N1"] = new Array();
                    self.rebond["AVG1"] = new Array();
                    self.tauxTransfo["N1"] = new Array();
                    self.tauxTransfo["AVG1"] = new Array();
                    self.tauxRetour["N1"] = new Array();
                    self.tauxRetour["AVG1"] = new Array();
                    self.caTTC["N1"] = new Array();
                    self.caTTC["O"] = new Array();
                    self.caTTC["AVG1"] = new Array();

                    self.totSessions["N1"] = 0;
                    self.totCA["N1"] = 0;
                    self.totTT["N1"] = 0;
                    self.totInscrits["N1"] = 0;

                    self.totSessions["%"] = 0;
                    self.totCA["%"] = 0;
                    self.totTT["%"] = 0;
                    self.totInscrits["%"] = 0;
                    
                    self.avgSes["N1"] = 0;
                    self.avgReb["N1"] = 0;
                    self.avgTT["N1"] = 0;
                    self.avgTR["N1"] = 0;
                    self.avgCA["N1"] = 0;
                    
                    forEach(stats.serie, function(stat, index, arr){
                      self.avgSes["N1"] += (stat.sessions) ? Number(stat.sessions) : 0;
                      self.avgReb["N1"] += (stat.rebond) ? Number(stat.rebond) : 0;
                      self.avgTT["N1"] += (stat.tauxTT) ? Number(stat.tauxTT) : 0; //(Number(stat.caTTC) > 0 ? Number(stat.caTTC) : 0) / Number(stat.sessions);
                      self.avgTR["N1"] += (stat.tauxR) ? Number(stat.tauxR) : 0;
                      self.avgCA["N1"] += (stat.caTTC) ? Number(stat.caTTC) : 0;

                      self.totSessions["N1"] += (stat.sessions) ? Number(stat.sessions) : 0;
                      self.totCA["N1"] += (stat.caTTC) ? Number(stat.caTTC.toFixed(0)) : 0;
                      self.totTT["N1"] += (stat.tauxTT) ? Number(stat.tauxTT.toFixed(2)) : 0;
                      self.totInscrits["N1"] += (stat.inscrits) ? Number(stat.inscrits.toFixed(0)) : 0;

                      self.sessions["N1"].push({x: new Date(stat.date), y: stat.sessions});
                      self.sessions["AVG1"].push({x: new Date(stat.date), y: 0});
                      self.rebond["N1"].push({x: new Date(stat.date), y: Number(stat.rebond.toFixed(2))});
                      self.rebond["AVG1"].push({x: new Date(stat.date), y: 0});
                      self.tauxTransfo["N1"].push({x: new Date(stat.date), y: (stat.tauxTT !== null) ? Number(stat.tauxTT.toFixed(2)) : 0}); //(Number(stat.caTTC) > 0 ? Number(stat.caTTC) : 0) /Number(stat.sessions)});
                      self.tauxTransfo["AVG1"].push({x: new Date(stat.date), y: 0});
                      self.tauxRetour["N1"].push({x: new Date(stat.date), y: (stat.tauxR !== null) ? Number(stat.tauxR.toFixed(2)) : 0}); //caTTC)/Number(stat.sessions)});
                      self.tauxRetour["AVG1"].push({x: new Date(stat.date), y: 0});
                      self.caTTC["N1"].push(stat.caTTC !== null ? Number(stat.caTTC.toFixed(0)) : 0 );
                      self.caTTC["O"].push(stat.caTTC !== null ? Number(stat.caTTC.toFixed(0))*1.2 : 0);
                      //self.caTTC["AVG1"].push({x: new Date(stat.caTTC), y: 0});
                    }, (ok, arr) => {
                      
                        self.avgSes["N1"] = self.avgSes["N1"] / arr.length;
                        self.avgReb["N1"] = self.avgReb["N1"] / arr.length;
                        self.avgTT["N1"] = self.avgTT["N1"] / arr.length;
                        self.avgTR["N1"] = self.avgTR["N1"] / arr.length;
                        self.avgCA["N1"] = self.avgCA["N1"] / arr.length;
                        self.totTT["N1"] = self.totTT["N1"] / arr.length;

                        self.seriesSessions.push({color: "#d58a08", name: new Date(this.periode.compDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.compTo).toLocaleDateString('fr-FR'), data: self.sessions["N1"], boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesSessions.push({color: "#f7d598", name: "Moyenne N-1",  data: self.sessions["AVG1"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgSes["N1"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesRebond.push({color: "#d58a08", name: new Date(this.periode.compDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.compTo).toLocaleDateString('fr-FR'), data: self.rebond["N1"], boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesRebond.push({color: "#f7d598", name: "Moyenne N-1",  data: self.rebond["AVG1"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgReb["N1"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesTransformation.push({color: "#d58a08", name: new Date(this.periode.compDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.compTo).toLocaleDateString('fr-FR'), data: self.tauxTransfo["N1"], boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesTransformation.push({color: "#f7d598", name: "Moyenne N-1",  data: self.tauxTransfo["AVG1"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgTT["N1"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesRetour.push({color: "#d58a08", name: new Date(this.periode.compDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.compTo).toLocaleDateString('fr-FR'), data: self.tauxRetour["N1"], boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesRetour.push({color: "#f7d598", name: "Moyenne N-1",  data: self.tauxRetour["AVG1"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = Number(self.avgTR["N1"].toFixed(2)); return rObj;}), boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        self.seriesCA.push({color: "#bbbbbb", name: "Objectif +20%", data: self.caTTC["O"]});
                        self.seriesCA.push({color: "#d58a08", name: new Date(this.periode.compDu).toLocaleDateString('fr-FR')+" - "+new Date(this.periode.compTo).toLocaleDateString('fr-FR'), data: self.caTTC["N1"]});
                        //self.seriesCA.push({color: "#7db5e6", name: "Moyenne N-1",  data: self.caTTC["AVG1"].map(obj => {var rObj = {x: 0, y: 0}; rObj.x = obj.x; rObj.y = self.avgCA["N1"]; return rObj;}), boostThreshold: 1, turboThreshold: 1000, xAxis: 1});
                        //this.start();

                        self.totSessions["%"] = (self.totSessions["N"] === 0) ? -100 : (self.totSessions["N1"] === 0) ? 100 : ((self.totSessions["N"] - self.totSessions["N1"]) / self.totSessions["N1"]) * 100;
                        self.totCA["%"] = (self.totCA["N"] === 0) ? -100 : (self.totCA["N1"] === 0) ? 100 : ((self.totCA["N"] - self.totCA["N1"]) / self.totCA["N1"]) * 100;
                        self.totTT["%"] = (self.totTT["N"] === 0) ? -100 : (self.totTT["N1"] === 0) ? 100 : ((self.totTT["N"] - self.totTT["N1"]) / self.totTT["N1"]) * 100;
                        self.totInscrits["%"] = (self.totInscrits["N"] === 0) ? -100 : (self.totInscrits["N1"] === 0) ? 100 : ((self.totInscrits["N"] - self.totInscrits["N1"]) / self.totInscrits["N1"]) * 100;
                        start1 = true;
                        if(start2)
                          this.start();
                        return;
                    });
                  });
              }else{
                console.dir(this.periode);
                start1 = true;
                if(start2)
                  this.start();
              }
            });
        }
      }
    ).then(
      () => {
        // On execute la requete pour les sessions par device / source 
        self.devices = { sessions: new Array<any>(), rebond: new Array<any>() };
        self.sources = { sessions: new Array<any>(), rebond: new Array<any>() };
        self.devices.sessions["N"] = new Array();
        self.devices.rebond["N"] = new Array();
        self.sources.sessions["N"] = new Array();
        self.sources.rebond["N"] = new Array();

        this.trafapi.dashboardByDevice(this.periode.selectedBtq, this.periode.dateDu, this.periode.dateTo, (this.periode.groupBy === 'e.year') ? ['year'] : (this.periode.groupBy === 'e.month') ? ['year', 'month'] : (this.periode.groupBy === 'e.week') ? ['year','week(date)'] : ['year', 'month', 'day']).toPromise().then(
          (devices: {serie: any[]}) => { 
            forEach(devices.serie, function(dev, index, arr) {
              if(index === 0){
                self.devices.sessions["N"].push({name:dev.device, y: dev.sesbydev, sliced: true, selected: true});
                self.devices.rebond["N"].push({name:dev.device, y: dev.rebbydev, sliced: true, selected: true});
              }else{
                self.devices.sessions["N"].push({name:dev.device, y: dev.sesbydev});
                self.devices.rebond["N"].push({name:dev.device, y: dev.rebbydev});
              }
            },(ok, arr) => {
              this.trafapi.dashboardBySource(this.periode.selectedBtq, this.periode.dateDu, this.periode.dateTo, (this.periode.groupBy === 'e.year') ? ['year'] : (this.periode.groupBy === 'e.month') ? ['year', 'month'] : (this.periode.groupBy === 'e.week') ? ['year','week(date)'] : ['year', 'month', 'day']).toPromise().then(
                (sources: {serie: any[]}) => { 
                  let maxs = 0;
                  let maxr = 0;
                  let idxs = 0;
                  let idxr = 0;
                  forEach(sources.serie, function(sou, index, arr) {
                    if(sou.sesbysou > maxs) {
                      maxs = sou.sesbysou;
                      idxs = index;
                    }
                    if(sou.rebbysou > maxr) {
                      maxr = sou.rebbysou;
                      idxr = index;
                    }
                  
                    self.sources.sessions["N"].push({name:sou.source, y: sou.sesbysou, sliced: undefined, selected: undefined});
                    self.sources.rebond["N"].push({name:sou.source, y: sou.rebbysou, sliced: undefined, selected: undefined});
                  },(ok, arr) => {
                    if(idxs > 0) {
                      self.sources.sessions["N"][idxs].sliced = true;
                      self.sources.sessions["N"][idxs].selected = true;
                    }
                    if(idxr > 0) {
                      self.sources.rebond["N"][idxr].sliced = true;
                      self.sources.rebond["N"][idxr].selected = true;
                    }

                    start2 = true;
                    if(start1)
                      this.start();
                  });
                });
            });
          });
      }
    );
  }
  
  ngAfterViewInit() {
  }

  start() {  
    
    this.devRebondOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: 'par périphérique'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          formatter: function() {
            var string = '<b>'+this.series.name+'</b><br/>';
            string += '<p>'+this.point.name+': <b>'+this.point.y+' %</b></p>';
            return string;
          },
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.name} {point.y: .1f} %',
                  distance: 3
              },
              showInLegend: false
          }
      },
      series: [{
          name: 'Périphériques',
          colorByPoint: true,
          data: this.devices.rebond["N"]
      }]
    };

    this.souRebondOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: 'par source de traffic'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          formatter: function() {
            var string = '<b>'+this.series.name+'</b><br/>';
            string += '<p>'+this.point.name+': <b>'+this.point.y+' %</b></p>';
            return string;
          },
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.name} {point.y: .1f} %',
              },
              showInLegend: true
          }
      },
      series: [{
          name: 'Sources',
          colorByPoint: true,
          data: this.sources.rebond["N"]
      }]
    };

    this.devSessionsOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: 'par périphérique'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          formatter: function() {
            var string = '<b>'+this.series.name+'</b><br/>';
            string += '<p>'+this.point.name+': <b>'+this.point.y+' %</b></p>';
            return string;
          },
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.name} {point.y: .1f} %',
                  distance: 3
              },
              showInLegend: false
          }
      },
      series: [{
          name: 'Périphériques',
          colorByPoint: true,
          data: this.devices.sessions["N"]
      }]
    };

    this.souSessionsOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: 'par source de traffic'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          formatter: function() {
            var string = '<b>'+this.series.name+'</b><br/>';
            string += '<p>'+this.point.name+': <b>'+this.point.y+' %</b></p>';
            return string;
          },
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.name} {point.y: .1f} %',
              },
              showInLegend: true
          }
      },
      series: [{
          name: 'Sources',
          colorByPoint: true,
          data: this.sources.sessions["N"]
      }]
    };

    this.caOptions = {                
      global: {
            useUTC: false
      },
      chart: {
            zoomType: 'x',
            type: 'column'
      },
      title: {
            text: 'CA Brut TTC'
      },
      boost: {
            useGPUTranslations: true
      },
      xAxis:{
            title: {
                    text: 'Date'
            },
            type: 'category',
            categories: this.histoCat
      },
      yAxis: {
            title: {
                    text: 'CA Brut TTC'
            },
            min: 0
      },
      tooltip: {
            label: {
                    attr: { zIndex: 0 }
            },
            headerFormat: '<span style="font-size: 10px">{series.name}</span>',
            formatter: function() {
              var string = '<b>'+this.series.name+'</b><br/>';
              string += '<p>'+this.x+': <b>'+this.point.y+'</b></p>';
              return string;
            },
            useHtml: true
      },
      plotOptions: {
        series: {
          grouping: true
        }
      },
      series: this.seriesCA.reverse()
    };

    this.sesOptions = {                
      global: {
            useUTC: false
      },
      chart: {
            zoomType: 'x',
            type: 'spline'
      },
      lineWidth: 5,
      title: {
            text: 'Nombre de sessions '
      },
      boost: {
            useGPUTranslations: true
      },
      xAxis: [{
            type: 'datetime',
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b',
                    year: '%Y'
            },
            title: {
                    text: 'Date'
            }
          },{
            type: 'datetime',
            visible: false
          }
      ],
      yAxis: {
            title: {
                    text: 'Nombre de sessions'
            },
            min: 0
      },
      tooltip: {
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b %y',
                    year: '%Y'
            },
            label: {
                    attr: { zIndex: 0 }
            },
            headerFormat: '<span style="font-size: 10px">{series.name}</span>',
            pointFormat: '{point.x:%e. %b %Y}: {point.y} ',
            formatter: function() {
              var string = '<b>'+this.series.name+'</b><br/>';
              string += '<p>'+this.point.x.toLocaleDateString('fr-FR')+': <b>'+this.point.y+'</b></p>';
              return string;
            },
            useHtml: true
      },
      plotOptions: {
            spline: {
                    marker: {
                            enabled: false
                    }
            }
      },
      series: this.seriesSessions
    };

    this.rebOptions = {                
      global: {
            useUTC: false
      },
      chart: {
            zoomType: 'x',
            type: 'spline'
      },
      lineWidth: 5,
      title: {
            text: 'Taux de rebond'
      },
      boost: {
            useGPUTranslations: true
      },
      xAxis: [{
            type: 'datetime',
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b',
                    year: '%Y'
            },
            title: {
                    text: 'Date'
            }
          },{
            type: 'datetime',
            visible: false
          }
      ],
      yAxis: {
            title: {
                    text: 'Taux de rebond'
            },
            min: 0
      },
      tooltip: {
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b %y',
                    year: '%Y'
            },
            label: {
                    attr: { zIndex: 0 }
            },
            headerFormat: '<span style="font-size: 10px">{series.name}</span>',
            pointFormat: '{point.x:%e. %b %Y}: {point.y} ',
            formatter: function() {
              var string = '<b>'+this.series.name+'</b><br/>';
              string += '<p>'+this.point.x.toLocaleDateString('fr-FR')+': <b>'+this.point.y+' %</b></p>';
              return string;
            },
            useHtml: true
      },
      plotOptions: {
            spline: {
                    marker: {
                            enabled: false
                    }
            }
      },
      series: this.seriesRebond
    };

    this.ttransfoOptions = {                
      global: {
            useUTC: false
      },
      chart: {
            zoomType: 'x',
            type: 'spline'
      },
      lineWidth: 5,
      title: {
            text: 'Taux de transformation (Nbre Commandes / Nbre Sessions)'
      },
      boost: {
            useGPUTranslations: true
      },
      xAxis: [{
            type: 'datetime',
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b',
                    year: '%Y'
            },
            title: {
                    text: 'Date'
            }
          },{
            type: 'datetime',
            visible: false
          }
      ],
      yAxis: {
            title: {
                    text: 'Taux de transformation'
            },
            min: 0
      },
      tooltip: {
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b %y',
                    year: '%Y'
            },
            label: {
                    attr: { zIndex: 0 }
            },
            headerFormat: '<span style="font-size: 10px">{series.name}</span>',
            pointFormat: '{point.x:%e. %b %Y}: {point.y} %',
            formatter: function() {
              var string = '<b>'+this.series.name+'</b><br/>';
              string += '<p>'+this.point.x.toLocaleDateString('fr-FR')+': <b>'+this.point.y+' %</b></p>';
              return string;
            },
            useHtml: true
      },
      plotOptions: {
            spline: {
                    marker: {
                            enabled: false
                    }
            }
      },
      series: this.seriesTransformation
    };

    this.tretourOptions = {                
      global: {
            useUTC: false
      },
      chart: {
            zoomType: 'x',
            type: 'spline'
      },
      lineWidth: 5,
      title: {
            text: 'Taux de retour (Montant Retours / Montant Commandes)'
      },
      boost: {
            useGPUTranslations: true
      },
      xAxis: [{
            type: 'datetime',
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b',
                    year: '%Y'
            },
            title: {
                    text: 'Date'
            }
          },{
            type: 'datetime',
            visible: false
          }
      ],
      yAxis: {
            title: {
                    text: 'Taux de retour'
            },
            min: 0
      },
      tooltip: {
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b %y',
                    year: '%Y'
            },
            label: {
                    attr: { zIndex: 0 }
            },
            headerFormat: '<span style="font-size: 10px">{series.name}</span>',
            pointFormat: '{point.x:%e. %b %Y}: {point.y} %',
            formatter: function() {
              var string = '<b>'+this.series.name+'</b><br/>';
              string += '<p>'+this.point.x.toLocaleDateString('fr-FR')+': <b>'+this.point.y+' %</b></p>';
              return string;
            },
            useHtml: true
      },
      plotOptions: {
            spline: {
                    marker: {
                            enabled: false
                    }
            }
      },
      series: this.seriesRetour
    };
    
  }

  ngOnDestroy(){
    if(this.btqSub) this.btqSub.unsubscribe();
    if(this.perSub) this.perSub.unsubscribe();
    Highcharts.charts.forEach(el => {
      if(el)
        el.destroy();
    });
  }
}
