/* tslint:disable */
import {
  Societe
} from '../index';

declare var Object: any;
export interface PoleInterface {
  "societeId": number;
  "nom": string;
  "id"?: number;
  societe?: Societe;
}

export class Pole implements PoleInterface {
  "societeId": number;
  "nom": string;
  "id": number;
  societe: Societe;
  constructor(data?: PoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Pole`.
   */
  public static getModelName() {
    return "Pole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Pole for dynamic purposes.
  **/
  public static factory(data: PoleInterface): Pole{
    return new Pole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Pole',
      plural: 'Poles',
      path: 'Poles',
      idName: 'id',
      properties: {
        "societeId": {
          name: 'societeId',
          type: 'number'
        },
        "nom": {
          name: 'nom',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        societe: {
          name: 'societe',
          type: 'Societe',
          model: 'Societe',
          relationType: 'belongsTo',
                  keyFrom: 'societeId',
          keyTo: 'id'
        },
      }
    }
  }
}
