import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserApi, LoopBackAuth } from '../../shared/sdk/services';
import { User, SDKToken } from '../../shared/sdk/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  public errorMessage: string = "";
  private user: User = new User();

  constructor(private formBuilder: FormBuilder,
              private userApi: UserApi,
              private router: Router,
              private auth: AuthService,
              private lbauth: LoopBackAuth) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]]
    });
  }

  onSubmit() {
    this.user.email = this.loginForm.get('email').value;
    this.user.password = this.loginForm.get('password').value;
    const self = this;
    this.auth.login(this.user, function(message, user) {
      console.dir("Message: "+message);
        self.errorMessage = message;
    });
  }

  onVerif() {
    if(this.userApi.isAuthenticated())
      this.errorMessage = "connecté";
    else
      this.errorMessage = "non connecté";
  }

}
