/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { RoleMapping } from '../../models/RoleMapping';
import { Role } from '../../models/Role';
import { Email } from '../../models/Email';
import { Societe } from '../../models/Societe';
import { Pole } from '../../models/Pole';
import { Droit } from '../../models/Droit';
import { Boutique } from '../../models/Boutique';
import { Ads } from '../../models/Ads';
import { Traffic } from '../../models/Traffic';
import { Ecommerce } from '../../models/Ecommerce';
import { EcommerceDetail } from '../../models/EcommerceDetail';
import { HitParade } from '../../models/HitParade';
import { HitParaderetour } from '../../models/HitParaderetour';
import { Contact } from '../../models/Contact';
import { Produit } from '../../models/Produit';
import { Transaction } from '../../models/Transaction';
import { Errorlog } from '../../models/Errorlog';
import { Newsletter } from '../../models/Newsletter';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    RoleMapping: RoleMapping,
    Role: Role,
    Email: Email,
    Societe: Societe,
    Pole: Pole,
    Droit: Droit,
    Boutique: Boutique,
    Ads: Ads,
    Traffic: Traffic,
    Ecommerce: Ecommerce,
    EcommerceDetail: EcommerceDetail,
    HitParade: HitParade,
    HitParaderetour: HitParaderetour,
    Contact: Contact,
    Produit: Produit,
    Transaction: Transaction,
    Errorlog: Errorlog,
    Newsletter: Newsletter,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
