import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

import { PeriodeService } from '../../services/periode.service'; 
import { Periode } from '../../services/periode.model';

import { Boutique } from '../../shared/sdk/models';
 
import * as d3 from 'd3';
import * as L from 'leaflet';

@Component({
  selector: 'app-georepartition',
  templateUrl: './georepartition.component.html',
  styleUrls: ['./georepartition.component.css']
})
export class GeorepartitionComponent implements OnInit, OnDestroy {

  public searchForm: FormGroup;
  public btqSub: Subscription;
  private perSub: Subscription;
  private dataSub: Subscription;
  private periode =  new Periode();

  public repartitionListe: MatTableDataSource<any> = new MatTableDataSource<any>();
  public displayedColumns : any[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public subQuantites: Subscription;
  public url: string;
  public key: string;
  public gb: string;
  public dateDu: string;
  public dateTo: string;
  public marques: Boutique[];
  public errorMessage: string;
  private csvData: any;
  private csvDataFirst: boolean;

  constructor(private http: HttpClient, private builder: FormBuilder, private perser: PeriodeService) {
      this.perSub = this.perser.getPeriode().subscribe(
        (per: any) => {
          this.periode = per;
          setTimeout(() => {
            this.btqSub = this.perser.getBoutiques().subscribe(
              (res: any[]) => {
                this.marques = res; 
                setTimeout(()=>{this.setup()});
              }
            );

          });
        }
      ); 
 }

  ngOnInit() {
    //this.buildMap();
  }

  setup() {
    this.ngOnDestroy();
    this.displayedColumns = ["nom","qty"];

    this.searchForm = new FormGroup(this.builder.group({
      dateDu: [this.periode.dateDu, Validators.required],
      dateTo: [new Date(this.periode.dateTo).toISOString().split('T')[0], Validators.required],
      marque: [this.periode.selectedBtq[0].toString(), Validators.required],
      groupBy: [this.periode.repartition, Validators.required]
    }).controls, {updateOn: 'change'});

    if(this.searchForm.valid) this.onSubmitForm();


    /*
    const myMap = L.map('mymap').setView([50.6311634, 3.0599573], 6);
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: 'my Map'
    }).addTo(myMap);
    */
  }

  buildMap() {  
    var self = this;
    const width = 550, height = 550;
    const path = d3.geoPath();
    const projection = d3.geoConicConformal()
    .center([2.454071, 46.279229])
    .scale(2600)
    .translate([width / 2, height / 2]);
    path.projection(projection);

    const svg = d3.select('#mymap').append("svg")
        .attr("id", "svg")
        .attr("width", width)
        .attr("height", height);

    var div = d3.select("body").append("div")   
        .attr("class", "tooltip")               
        .style("opacity", 0);

    const deps = svg.append("g");

    d3.json("../../../assets/france-geojson-master/regions-version-simplifiee.geojson").then(function(geojson: any) {
      deps.selectAll("path")
          .data(geojson.features)
          .enter()
          .append("path")
          .attr("d", path)
          .on("mouseover", function(d: any) {
              div.transition()        
                  .duration(200)
                  .style("background", "white")
                  .style("padding","5px")
                  .style("color", "black")
                  .style("opacity", .9);      
              div.html(d.properties.nom)  
                  .style("left", (d3.event.pageX + 30) + "px")     
                  .style("top", (d3.event.pageY - 30) + "px")
          })
          .on("mouseout", function(d) {
              div.style("opacity", 0);
              div.html("")
                  .style("left", "-500px")
                  .style("top", "-500px");
          });   
    });
  }

  ngOnDestroy() {
    if(this.subQuantites) this.subQuantites.unsubscribe();
    if(this.perSub) this.perSub.unsubscribe();
    if(this.btqSub) this.btqSub.unsubscribe();
    if(this.dataSub) this.dataSub.unsubscribe();
  }

  onSubmitForm() {
    var self = this;
    this.csvDataFirst = true;

    var marque = this.marques.find(
      function(elem) {
        return elem.id.toString() === self.searchForm.get("marque").value;
      }
    );
    
    this.url = marque.url;
    this.key = marque.token;
    this.periode.dateDu = this.searchForm.get("dateDu").value;
    this.periode.dateTo = this.searchForm.get("dateTo").value;
    
    var compDu = new Date(this.searchForm.get("dateDu").value);
    var compTo = new Date(this.searchForm.get("dateTo").value);
    compDu.setFullYear(compDu.getFullYear() -1);
    compTo.setFullYear(compTo.getFullYear() -1);
    this.periode.compDu = compDu.toISOString().split('T')[0];
    this.periode.compTo = compTo.toISOString().split('T')[0];
    
    this.periode.repartition = this.searchForm.get("groupBy").value;

    var quantiteUrl = this.url+"/modules/ras_expeditions/api.php?ws_key="+this.key+"&url=geoloc&groupby="+this.periode.repartition+"&dateDu="+this.periode.dateDu+"&dateTo="+this.periode.dateTo;
    this.subQuantites = this.http.get(quantiteUrl)
      .pipe(
        map((res: any[]) => {
          return res.map(re => {
            if(typeof re.region == 'object') console.dir(re.region);
            var ren = {nom: 'ERREUR DE CODE POSTAL', qty: 0};
            if(re.region) ren.nom = re.region;
            if(re.dept) ren.nom = re.dept+" ("+re.code+")";
            if(re.ville) ren.nom = re.ville+" ("+re.code_postal.substring(0,2)+")";
            ren.qty = re.qty;
            //return ren.nom ? ren : false;
            return ren;
          });
        }),
        catchError(this.handleError)
      )
      .subscribe(
        (res: any) => {
          this.errorMessage = null;
          this.repartitionListe = new MatTableDataSource(res);
          setTimeout(()=>{
            this.dataSub = this.repartitionListe.connect().subscribe(
              (d) => {
                if(this.csvDataFirst) {
                  this.csvData = d; 
                  this.csvDataFirst = false;
                }
              }
            );
            this.repartitionListe.sort = this.sort;
            this.repartitionListe.paginator = this.paginator;
          });
        },
        (e) => {
          this.errorMessage = e;
        }
      );

  }

  applyFilter(filterValue: string) {
    this.repartitionListe.filter = filterValue.trim().toLowerCase();
  }

  exportCsv() {
    new Angular5Csv(this.csvData, 'Répartition Géographique', {fieldSeparator: ';'});
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(`Erreur: `+error.error);
  }

}
