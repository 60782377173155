/******************************************************
 * Class Product pour le filtre de sélection Produits
 */

export class Product {
    public saison: any;
    public collection: any;
    public modele: any;
    public matiere: any;
    public coloris: any;
    public transmis: any;
    public ageMin: any;
    public ageMax: any;
    public liste: any;
}