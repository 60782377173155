import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User, FireLoopRef } from './shared/sdk/models';
import { RealTime, UserApi } from './shared/sdk/services';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Windy Art - Dashboard';

  public isAuth: boolean;
  private subscription: Subscription;
/*
  private todo      : Todo = new Todo();
  private todos     : Todo[] = new Array<Todo>();
*/  
  private user      : User = new User();
  private users     : User[] = new Array<User>();

 // private reference : FireLoopRef<Todo>;
  private tableUsers: FireLoopRef<User>;

  constructor(private rt: RealTime,
              private userApi: UserApi,
              private auth: AuthService) {
    
    //this.isAuth = this.userApi.isAuthenticated();
    this.subscription = this.auth.getAuth().subscribe(
      (user: User) => {
        this.user = user;
        this.isAuth = (typeof this.user.id !== 'undefined' ? true : false);
      }
    );
/*    
    this.userApi.getCurrent().subscribe((user: User) => {
      this.user = user;
      console.dir(user);
    });

    this.rt.onReady().subscribe(() => {  
    this.tableUsers = this.rt.FireLoop.ref<User>(User);
    this.tableUsers.on('change', {
      offset: 0,
      limit: 10,
      order: 'id DESC'
    }).subscribe((users: User[]) => {
      this.users = users;
    });
      
    });

      
      this.reference = this.rt.FireLoop.ref<Todo>(Todo);
      this.reference.on('change', {
        offset: 0,
        limit: 10,
        order: 'id DESC'
      }).subscribe((todos: Todo[]) => {
        this.todos = todos;
      });  
*/    
  }
  ngOnInit() {
    this.auth.restoreUser();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    
  }
/*
  createTodo(): void {
    this.reference.create(this.todo).subscribe(() => this.todo = new Todo());
  }

  updateTodo(todo: Todo): void {
    this.reference.upsert(todo).subscribe();
  }

  removeTodo(todo: Todo): void {
    this.reference.remove(todo).subscribe();
  }

  createUser(): void {
    this.tableUsers.create(this.user).subscribe(() => this.user = new User());
  }

  updateUser(user: User): void {
    this.tableUsers.upsert(user).subscribe();
  }

  removeUser(user: User): void {
    this.tableUsers.remove(user).subscribe();
  }
  */
}
