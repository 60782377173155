import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserApi, LoopBackAuth } from '../../shared/sdk/services';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() isAuth: boolean;
  public isAdmin: boolean = false;
  private email: string;
  private subscription: Subscription;

  isMatched: boolean = this.breakpointObserver.isMatched(Breakpoints.Handset);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, 
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private userApi: UserApi,
              private auth: AuthService,
              private lbauth: LoopBackAuth) {
    this.subscription = this.auth.getAdmin().subscribe(
        (resp: any) => {
              this.isAdmin = resp;
        }
    );
    const user: any = this.lbauth.getCurrentUserData();
    if(typeof user.email !== 'undefined') this.email = user.email;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  menuClose(drawer: any) {
    if(this.breakpointObserver.isMatched(Breakpoints.Handset))
      return drawer.close();
  }

  onProfile() {
    this.router.navigate(['profile']);
  }

  onLogout() {
    this.auth.logout();
    this.router.navigate(['/']);
  }

}
