/* tslint:disable */
import {
  Boutique
} from '../index';

declare var Object: any;
export interface AdsInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "date": Date;
  "sessions"?: number;
  "rebond"?: number;
  "impressions"?: number;
  "adClicks"?: number;
  "adCost"?: number;
  "roas"?: number;
  "id"?: number;
  boutique?: Boutique;
}

export class Ads implements AdsInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "date": Date;
  "sessions": number;
  "rebond": number;
  "impressions": number;
  "adClicks": number;
  "adCost": number;
  "roas": number;
  "id": number;
  boutique: Boutique;
  constructor(data?: AdsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Ads`.
   */
  public static getModelName() {
    return "Ads";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Ads for dynamic purposes.
  **/
  public static factory(data: AdsInterface): Ads{
    return new Ads(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Ads',
      plural: 'Adss',
      path: 'Adss',
      idName: 'id',
      properties: {
        "boutiqueId": {
          name: 'boutiqueId',
          type: 'number'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "day": {
          name: 'day',
          type: 'number'
        },
        "week": {
          name: 'week',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "sessions": {
          name: 'sessions',
          type: 'number'
        },
        "rebond": {
          name: 'rebond',
          type: 'number'
        },
        "impressions": {
          name: 'impressions',
          type: 'number'
        },
        "adClicks": {
          name: 'adClicks',
          type: 'number'
        },
        "adCost": {
          name: 'adCost',
          type: 'number'
        },
        "roas": {
          name: 'roas',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        boutique: {
          name: 'boutique',
          type: 'Boutique',
          model: 'Boutique',
          relationType: 'belongsTo',
                  keyFrom: 'boutiqueId',
          keyTo: 'id'
        },
      }
    }
  }
}
