import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { forEach } from 'async-foreach';

import { PeriodeService } from '../../services/periode.service'; 
import { Periode } from '../../services/periode.model';
import { HitParadeApi } from '../../shared/sdk/services';

import { Angular5Csv } from 'angular5-csv/Angular5-csv';

import * as Highcharts from 'highcharts';
import * as highchartsMore from 'highcharts/highcharts-more.src';exporting(Highcharts);
import * as exporting from 'highcharts/modules/exporting.src';exporting(Highcharts);
import * as boostCanvas from 'highcharts/modules/boost-canvas.src'; boostCanvas(Highcharts);
import * as boost from 'highcharts/modules/boost.src';import { ok } from 'assert';
boost(Highcharts);

@Component({
  selector: 'app-hitparadesaison',
  templateUrl: './hitparadesaison.component.html',
  styleUrls: ['./hitparadesaison.component.css']
})
export class HitparadesaisonComponent implements OnInit, OnDestroy {

  // Variable pour la période d'observation
  private perSub: Subscription;
  private periode =  new Periode();

  public sortBy: boolean;
  public compTo: boolean;

  public hitparade: any[];
  public histocat: any[] = new Array<any>();
  public serieq: any[];
  public seriem: any[];
  public seriet: any[];
  public seriec: any[];
  public totQte: any[];
  public totMnt: any[];

  // Graphiques
  HG = Highcharts;

  // Options pour les graphiques
  saisOptions = {};// required
  qteOptions = {};
  caOptions = {};

  chartConstructor = 'chart'; // optional string, defaults to 'chart'
  chartCallback = function (chart: any) {} // optional function, defaults to null
  updateFlag = true; // optional boolean
  oneToOneFlag = true; // optional boolean, defaults to false

  constructor(private hpapi: HitParadeApi, private perser: PeriodeService) {  

    this.sortBy = true;
    this.compTo = false;

    this.perSub = this.perser.getPeriode().subscribe(
      (per: any) => {
        this.periode = per;
        if(per.selectedBtq !== null) this.setup(per.selectedBtq);
      }
    ); 
  }

  setup(id: any) {  
    var self = this;
    this.serieq = new Array<any>();
    this.seriem = new Array<any>();
    this.seriet = new Array<any>();
    this.seriec = new Array<any>();
    this.histocat = new Array<any>();
    this.totQte = new Array();
    this.totMnt = new Array();
    this.hpapi.saisonsByYMD(this.periode.selectedBtq, this.periode.dateDu, this.periode.dateTo, (this.periode.groupBy === 'e.year') ? ['e.year'] : (this.periode.groupBy === 'e.month') ? ['e.year', 'e.month'] : (this.periode.groupBy === 'e.week') ? ['e.year','week(e.date, 7)'] : ['e.year', 'e.month', 'e.day']).toPromise().then(
      (stats: {serie: any[]}) => {
        this.hitparade = stats.serie;
        this.totQte["maxq"] = 0;
        this.totMnt["maxc"] = "";
        var slice: number;
        forEach(stats.serie, function(stat, index, arr) {
          if(slice !== (self.periode.groupBy === 'e.year' ? stat.year : self.periode.groupBy === 'e.month' ? stat.month : self.periode.groupBy === 'e.week' ? stat.week : stat.day)) {
            self.histocat.push(self.periode.groupBy === 'e.year' ? new Date(stat.date).getFullYear() : self.periode.groupBy === 'e.month' ? new Date(stat.date).toLocaleString('fr-FR',{month: "long"}) : self.periode.groupBy === 'e.week' ? "Sem. "+Number(stat.week+1) : new Date(stat.date).toLocaleString('fr-FR', {day: '2-digit', month: 'long'}));
            slice = self.periode.groupBy === 'e.year' ? stat.year : self.periode.groupBy === 'e.month' ? stat.month : self.periode.groupBy === 'e.week' ? stat.week : stat.day;
          }
          if(typeof self.totQte[stat.code_saison+"-"+stat.lib_saison] !== 'undefined')
            self.totQte[stat.code_saison+"-"+stat.lib_saison] += stat.qte ? Number(stat.qte) : 0;
          else
            self.totQte[stat.code_saison+"-"+stat.lib_saison] = stat.qte ? Number(stat.qte) : 0;
        
          if(self.totQte[stat.code_saison+"-"+stat.lib_saison] > self.totQte["maxq"]) {
            self.totQte["maxq"] = self.totQte[stat.code_saison+"-"+stat.lib_saison];
          }

          if(typeof self.totMnt[stat.code_saison+"-"+stat.lib_saison] !== 'undefined')
            self.totMnt[stat.code_saison+"-"+stat.lib_saison] += stat.caTTC ? Number(stat.caTTC) : 0;
          else
            self.totMnt[stat.code_saison+"-"+stat.lib_saison] = stat.caTTC ? Number(stat.caTTC) : 0;

          if(self.totMnt[stat.code_saison+"-"+stat.lib_saison] > self.totMnt["maxc"]) {
            self.totMnt["maxc"] = self.totMnt[stat.code_saison+"-"+stat.lib_saison];
          }

          if(self.seriem.indexOf(stat.code_saison+"-"+stat.lib_saison) == -1) {
            self.serieq.push({name: stat.code_saison+"-"+stat.lib_saison, data: []});
            self.seriet.push({name: stat.code_saison+"-"+stat.lib_saison, sliced: undefined, selected: undefined, y: 0});
            self.seriec.push({name: stat.code_saison+"-"+stat.lib_saison, sliced: undefined, selected: undefined, y: 0});
            self.seriem.push(stat.code_saison+"-"+stat.lib_saison);
          }

        }, (ok, arr) => {
          self.seriet = self.seriet.map(obj => {
            obj.y = self.totQte[obj.name];
            if(self.totQte[obj.name] == self.totQte["maxq"]) {
              console.dir(self.totQte);
              obj.selected = true;
              obj.sliced = true;
            }
            return obj;
          });
          self.seriec = self.seriec.map(obj => {
            obj.y = self.totMnt[obj.name];
            if(self.totMnt[obj.name] == self.totMnt["maxc"]) {
              obj.selected = true;
              obj.sliced = true;
            }
            return obj;
          });

          forEach(self.hitparade, function(hit, index, arr) {
            var indexH = self.periode.groupBy === 'e.year' ? new Date(hit.date).getFullYear() : self.periode.groupBy === 'e.month' ? new Date(hit.date).toLocaleString('fr-FR',{month: "long"}) : self.periode.groupBy === 'e.week' ? "Sem. "+Number(hit.week+1) : new Date(hit.date).toLocaleString('fr-FR', {day: '2-digit', month: 'long'});
            self.serieq = self.serieq.map(obj => {
              if(obj.name === hit.code_saison+"-"+hit.lib_saison) {
                for(var i = 0; i < self.histocat.length; i++) {
                  if(i === self.histocat.indexOf(indexH)) obj.data[self.histocat.indexOf(indexH)] = { y: hit.qte, z: hit.caTTC };
                  else if(typeof obj.data[i] === 'undefined') obj.data[i] = { y: 0, z: 0 }; 
                }
              }
              return obj;
            });


          },(ok, arr) => {
            this.start();
          });
        });  
      });
  }

  start() {

    this.saisOptions = {                
      global: {
            useUTC: false
      },
      chart: {
            zoomType: 'x',
            type: 'spline'
      },
      lineWidth: 5,
      title: {
            text: 'Saison des produits vendus '
      },
      boost: {
            useGPUTranslations: true
      },
      xAxis: {
        title: {
                text: 'Période'
        },
        type: 'category',
        categories: this.histocat
  },
      yAxis: {
            title: {
                    text: 'Nombre de produits vendus'
            },
            min: 0
      },
      tooltip: {
            dateTimeLabelFormats: {
                    day: '%e. %b',
                    month: '%b %y',
                    year: '%Y'
            },
            label: {
                    attr: { zIndex: 0 }
            },
            headerFormat: '<span style="font-size: 10px">{series.name}</span>',
            pointFormat: '{point.x:%e. %b %Y}: {point.y} ',
            formatter: function() {
              var string = '<b>'+this.series.name+'</b><br/>';
              string += '<p>'+this.x+': <b>'+this.y+' produits </b>('+this.point.z+' € caTTC)</p>';
              return string;
            },
            useHtml: true
      },
      plotOptions: {
            spline: {
                    marker: {
                            enabled: false
                    }
            }
      },
      series: this.serieq
    };

    this.qteOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: 'Quantités par saison'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          formatter: function() {
            var string = '<b>'+this.series.name+'</b><br/>';
            string += '<p>'+this.point.name+': <b>'+this.point.y+' produits</b></p>';
            return string;
          },
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.name} {point.percentage: .1f} %',
              },
              showInLegend: true
          }
      },
      series: [{
          name: 'Quantités',
          colorByPoint: true,
          data: this.seriet
      }]
    };

    this.caOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: 'CA TTC par saison'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          formatter: function() {
            var string = '<b>'+this.series.name+'</b><br/>';
            string += '<p>'+this.point.name+': <b>'+Number(this.point.y).toFixed(2)+' euros TTC</b></p>';
            return string;
          },
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.name} {point.percentage: .1f} %',
              },
              showInLegend: true
          }
      },
      series: [{
          name: 'CA TTC',
          colorByPoint: true,
          data: this.seriec
      }]
    };

  }

  exportCsv() {
    new Angular5Csv(this.hitparade, 'Saison des produits vendus', {fieldSeparator: ';', showLabels: true, headers:['Année','Mois','Semaine','Jour','Date','Code saison','Libellé saison','Quantité','CA TTC']});
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if(this.perSub) this.perSub.unsubscribe();
    Highcharts.charts.forEach(el => {
      if(el)
        el.destroy();
    });
  }

}
