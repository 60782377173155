import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

import { PeriodeService } from '../../services/periode.service'; 
import { Periode } from '../../services/periode.model';
import { HitParaderetourApi } from '../../shared/sdk/services';

@Component({
  selector: 'app-hitparade',
  templateUrl: './hitparaderetour.component.html',
  styleUrls: ['./hitparaderetour.component.css']
})
export class HitparaderetourComponent implements OnInit, OnDestroy {

  // Variable pour la période d'observation
  private perSub: Subscription;
  private periode =  new Periode();

  public sortBy: boolean;
  public compTo: boolean;

  public hitparade: MatTableDataSource<any> = new MatTableDataSource<any>();
  public displayedColumns : any[];

  private csvData: any;
  private csvDataFirst: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private hpapi: HitParaderetourApi, private perser: PeriodeService, public dialog: MatDialog) {

    this.sortBy = false;
    this.compTo = false;
    
    this.perSub = this.perser.getPeriode().subscribe(
      (per: any) => {
        this.periode = per;
        if(per.selectedBtq !== null) this.setup(per.selectedBtq);
      }
    ); 
  }

  ngOnInit() {
  }

  setup(id: any) {
    this.csvDataFirst = true;
    this.displayedColumns = ['couleur','reference','nom','raison','qte']; 
    
    this.hpapi.hitByYMD(this.periode.selectedBtq, this.periode.dateDu, this.periode.dateTo, (this.periode.groupBy === 'e.year') ? ['e.year'] : (this.periode.groupBy === 'e.month') ? ['e.year', 'e.month'] : (this.periode.groupBy === 'e.week') ? ['e.year','week(e.date)'] : ['e.year', 'e.month', 'e.day']).toPromise().then(
      (stats: {serie: any[]}) => {
        this.csvData = stats.serie.map((elem: any) => {
          return { "reference": elem.reference, "nom": elem.nom, "raison": elem.raison, "qte": elem.qte};
        });
        this.hitparade = new MatTableDataSource(stats.serie);
        setTimeout(()=>{this.hitparade.sort = this.sort;this.hitparade.paginator = this.paginator;})
      });
  }

  exportCsv() {
    new Angular5Csv(this.csvData, 'Hit parade des retours', {fieldSeparator: ';', showLabels: true, headers: ['Référence','Nom','Motif','Quantité']});
  }

  applyFilter(filterValue: string) {
    this.hitparade.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    if(this.perSub)
      this.perSub.unsubscribe();

  }

}
