/* tslint:disable */

declare var Object: any;
export interface EcommerceDetailInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "quantite"?: number;
  "montant"?: number;
  "remise"?: number;
  "pays": string;
  "date": Date;
  "id"?: number;
}

export class EcommerceDetail implements EcommerceDetailInterface {
  "boutiqueId": number;
  "year": number;
  "month": number;
  "day": number;
  "week": number;
  "quantite": number;
  "montant": number;
  "remise": number;
  "pays": string;
  "date": Date;
  "id": number;
  constructor(data?: EcommerceDetailInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EcommerceDetail`.
   */
  public static getModelName() {
    return "EcommerceDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EcommerceDetail for dynamic purposes.
  **/
  public static factory(data: EcommerceDetailInterface): EcommerceDetail{
    return new EcommerceDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EcommerceDetail',
      plural: 'EcommerceDetails',
      path: 'EcommerceDetails',
      idName: 'id',
      properties: {
        "boutiqueId": {
          name: 'boutiqueId',
          type: 'number'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "day": {
          name: 'day',
          type: 'number'
        },
        "week": {
          name: 'week',
          type: 'number'
        },
        "quantite": {
          name: 'quantite',
          type: 'number',
          default: 0
        },
        "montant": {
          name: 'montant',
          type: 'number',
          default: 0
        },
        "remise": {
          name: 'remise',
          type: 'number',
          default: 0
        },
        "pays": {
          name: 'pays',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
