/* tslint:disable */
import {
  Errorlog
} from '../index';

declare var Object: any;
export interface ContactInterface {
  "compagnie": string;
  "code_client": number;
  "code_boutique": string;
  "civilite"?: string;
  "nom"?: string;
  "prenom"?: string;
  "adresse1"?: string;
  "adresse2"?: string;
  "code_postal"?: string;
  "ville"?: string;
  "pays"?: string;
  "telephone"?: string;
  "mobile"?: string;
  "email"?: string;
  "date_naissance"?: Date;
  "sexe"?: string;
  "langue"?: string;
  "newsletter"?: number;
  "optin"?: boolean;
  "actif"?: boolean;
  "supprime"?: boolean;
  "date_creation"?: Date;
  "date_modification"?: Date;
  "date_connexion"?: Date;
  "transmis"?: boolean;
  "id"?: number;
  error?: Errorlog;
}

export class Contact implements ContactInterface {
  "compagnie": string;
  "code_client": number;
  "code_boutique": string;
  "civilite": string;
  "nom": string;
  "prenom": string;
  "adresse1": string;
  "adresse2": string;
  "code_postal": string;
  "ville": string;
  "pays": string;
  "telephone": string;
  "mobile": string;
  "email": string;
  "date_naissance": Date;
  "sexe": string;
  "langue": string;
  "newsletter": number;
  "optin": boolean;
  "actif": boolean;
  "supprime": boolean;
  "date_creation": Date;
  "date_modification": Date;
  "date_connexion": Date;
  "transmis": boolean;
  "id": number;
  error: Errorlog;
  constructor(data?: ContactInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Contact`.
   */
  public static getModelName() {
    return "Contact";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Contact for dynamic purposes.
  **/
  public static factory(data: ContactInterface): Contact{
    return new Contact(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Contact',
      plural: 'contacts',
      path: 'contacts',
      idName: 'id',
      properties: {
        "compagnie": {
          name: 'compagnie',
          type: 'string'
        },
        "code_client": {
          name: 'code_client',
          type: 'number'
        },
        "code_boutique": {
          name: 'code_boutique',
          type: 'string'
        },
        "civilite": {
          name: 'civilite',
          type: 'string'
        },
        "nom": {
          name: 'nom',
          type: 'string'
        },
        "prenom": {
          name: 'prenom',
          type: 'string'
        },
        "adresse1": {
          name: 'adresse1',
          type: 'string'
        },
        "adresse2": {
          name: 'adresse2',
          type: 'string'
        },
        "code_postal": {
          name: 'code_postal',
          type: 'string'
        },
        "ville": {
          name: 'ville',
          type: 'string'
        },
        "pays": {
          name: 'pays',
          type: 'string'
        },
        "telephone": {
          name: 'telephone',
          type: 'string'
        },
        "mobile": {
          name: 'mobile',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "date_naissance": {
          name: 'date_naissance',
          type: 'Date'
        },
        "sexe": {
          name: 'sexe',
          type: 'string'
        },
        "langue": {
          name: 'langue',
          type: 'string'
        },
        "newsletter": {
          name: 'newsletter',
          type: 'number'
        },
        "optin": {
          name: 'optin',
          type: 'boolean'
        },
        "actif": {
          name: 'actif',
          type: 'boolean'
        },
        "supprime": {
          name: 'supprime',
          type: 'boolean'
        },
        "date_creation": {
          name: 'date_creation',
          type: 'Date'
        },
        "date_modification": {
          name: 'date_modification',
          type: 'Date'
        },
        "date_connexion": {
          name: 'date_connexion',
          type: 'Date'
        },
        "transmis": {
          name: 'transmis',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        error: {
          name: 'error',
          type: 'Errorlog',
          model: 'Errorlog',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'id_contact'
        },
      }
    }
  }
}
