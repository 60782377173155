import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, interval, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { Ecommerce, Traffic, Boutique, FireLoopRef } from '../../shared/sdk/models';
import { RealTime, EcommerceApi, TrafficApi, BoutiqueApi } from '../../shared/sdk/services';

import { forEach } from 'async-foreach';
import { MatRadioChange } from '@angular/material';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-synthese',
  templateUrl: './synthese.component.html',
  styleUrls: ['./synthese.component.css']
})
export class SyntheseComponent implements OnInit, OnDestroy {

  // Variables pour formulaire
  public syntheseForm: FormGroup;
  public dateRef: string;
  public compRef: string;
  public dateComp: string;

  // Promises pour les tableaux
  public ecProm$: Observable<boolean>;
  public trProm$: Observable<boolean>;

  public dateRefLib: string;
  public compRefLib: string;
  public moisLib: string;
  public jourLib: number;
  public moisJourLib: string;

  private dateSynthese1: Date;
  private dateSynthese2: Date;
  public jourReference: string;
  private dt = new Date();
  private day: number;
  private month: number;
  private year: number;
  private tymer = interval(60000);

  private refEcomm: FireLoopRef<Ecommerce>;
  private refTraff: FireLoopRef<Traffic>;
  private subscriptions: Subscription[] = new Array<Subscription>();

  public eComms: any[] = new Array<any>();
  public traffs: any[] = new Array<any>();
  public totEc: number = 0;
  public totCde: number = 0;

  public boutiks: Boutique[] = new Array<Boutique>();
  public btnWidth: number = 0;
  public allBtk: any[] = new Array<any>();
  public serieTabloEC: any[]; // = new Array<any>();
  public serieTabloTR: any[]; // = new Array<any>();

  constructor(private rt: RealTime,
    private ecapi: EcommerceApi,
    private trapi: TrafficApi,
    private btkapi: BoutiqueApi, 
    private builder: FormBuilder) { 

    // Dates pour le Live
    this.day = this.dt.getDate();
    this.month = this.dt.getMonth()+1;
    this.year = this.dt.getFullYear();

    // Dates pour la synthese
    this.dateSynthese1 = new Date();
    this.dateSynthese2 = new Date();
    this.dateSynthese1.setDate(this.dateSynthese1.getDate() -1);
    this.dateSynthese2.setDate(this.dateSynthese2.getDate() -1);
    this.dateSynthese2.setFullYear(this.dateSynthese2.getFullYear() -1);

    this.jourReference = new Date(this.dateSynthese1).toLocaleString('fr-FR', {year: 'numeric', month: "long", day: "2-digit"});
    this.dateComp = 'sameDate';
    this.dateRef = new Date(this.dateSynthese1).toISOString().split('T')[0];
    this.compRef = new Date(this.dateSynthese2).toISOString().split('T')[0];

    if(this.rt.connection.isConnected() && this.rt.connection.authenticated) {
      this.subscriptions.push(this.rt.onReady().subscribe(() => this.setup()));
    }else{
      this.subscriptions.push(this.rt.onAuthenticated().subscribe(() => this.setup()));
      this.subscriptions.push(this.rt.onReady().subscribe());
    }
    //this.initTablo(new Date(this.dateRef), new Date(this.compRef));

  }

  setup() {
    var self = this;
    this.ngOnDestroy(); 
      this.subscriptions.push(this.btkapi.find({where:{id:{lt:6}}}).subscribe(
        (btks: Boutique[]) => {
          this.boutiks = btks;
          this.btnWidth = 100 / (btks.length+1);
          forEach(btks, function(btk, index, arr) {
            self.allBtk[btk.id] = {couleur: btk.couleur, nom: btk.nom, ca: 0, tt: 0, nbc: 0, sessions: 0, rebond: 0, m: 0, init: true};
          }, (ok, arr) => { 
              self.refEcomm = self.rt.FireLoop.ref<Ecommerce>(Ecommerce);
              self.subscriptions.push(self.refEcomm.on('change', {
                  where: { and: [{day: self.day},{month:self.month}, {year:self.year}]},
                  order: 'id DESC'
                }).subscribe((ecommerces: Ecommerce[]) => {
                  self.eComms = ecommerces;
                  self.totEc = 0;
                  self.totCde = 0;
                  forEach(ecommerces, function(ec,index,arr) {
                    self.allBtk[ec.boutiqueId].ca = Number(ec.montantPaye) * 1.2;
                    self.allBtk[ec.boutiqueId].nbc = Number(ec.nbreCommandes);
                    self.allBtk[ec.boutiqueId].tt = (Number(ec.nbreCommandes) / Number(self.allBtk[ec.boutiqueId].sessions)) * 100;
                    self.totEc += Number(ec.montantPaye)*1.2;
                    self.totCde += Number(ec.nbreCommandes);
                  }, (ok, arr) => {
                    self.traffs.map(obj => {
                      var ro = obj;
                      ro.tt = (self.totCde  / obj.sessions) * 100;
                      return ro;
                    });
                  });
                }));
      
            if(self.traffs.length === 0) self.getTraffic();
        
            self.subscriptions.push(self.tymer.subscribe(() => { self.getTraffic()})); 

          });
        }
      ));
  }

  getTraffic() {
    var self = this;
    this.trapi.find({where:{and: [{day: this.day}, {month: this.month}, {year: this.year}]}, order: "boutiqueId ASC"}).toPromise().then(
      (trafs: Traffic[]) => {
        var totSessions = 0;
        var totRebond = 0;       
        self.traffs = new Array<any>(); 
        var m = 0;
        forEach(trafs, function(traf, index, arr) {
          if(self.allBtk[traf.boutiqueId].init) {
            self.allBtk[traf.boutiqueId].sessions = Number(traf.sessions);
            self.allBtk[traf.boutiqueId].rebond = Number(traf.rebond);
            self.allBtk[traf.boutiqueId].m = 1;
            self.allBtk[traf.boutiqueId].init = false;
          }else{
            self.allBtk[traf.boutiqueId].sessions += Number(traf.sessions);
            self.allBtk[traf.boutiqueId].rebond += Number(traf.rebond);
            self.allBtk[traf.boutiqueId].m += 1;
          }
          totSessions += Number(traf.sessions);
          totRebond += Number(traf.rebond);
          m += 1;
        }, (ok, arr) => {
          self.traffs.push({sessions: totSessions, rebond: totRebond/m, tt: (self.totCde / totSessions)*100});
          self.allBtk.map((obj) => { var rObj = obj; rObj.rebond = obj.rebond / obj.m; rObj.tt = (obj.nbc / obj.sessions) * 100; rObj.init = true; return rObj;});
        });
      }
    );
  }

  ngOnInit() {
    this.initForm();
    this.initTablo(new Date(this.dateRef), new Date(this.compRef));
  }

  initTablo(d: Date, d1: Date) {
    var j = d;
    var j1 = d1;

    var m = j.getMonth()+1;
    var y = j.getFullYear();

    this.ecProm$ = this.ecapi.tableauSynthese(j.getDate(), j1.getDate(), m, y)
    .pipe(
      map(res => { return res.serie }),
      map(arr => {
        arr.map( obj => {
          var rObj = obj;
          rObj.dd = (obj.d1 > 0) ? ((obj.d - obj.d1) / obj.d1) * 100 : (obj.d === obj.d1) ? 0 : 100;
          rObj.mm = (obj.m1 > 0) ? ((obj.m - obj.m1) / obj.m1) * 100 : (obj.m === obj.m1) ? 0 : 100;
          rObj.mm1 = (obj.m2 > 0) ? ((obj.m1 - obj.m2) / obj.m2) * 100 : (obj.m1 === obj.m2) ? 0 : 100;
          rObj.mm2 = (obj.m3 > 0) ? ((obj.m2 - obj.m3) / obj.m3) * 100 : (obj.m2 === obj.m3) ? 0 : 100;
          rObj.yy = (obj.y1 > 0) ? ((obj.y - obj.y1) / obj.y1) * 100 : (obj.y === obj.y1) ? 0 : 100;
          rObj.yy1 = (obj.y2 > 0) ? ((obj.y1 - obj.y2) / obj.y2) * 100 : (obj.y1 === obj.y2) ? 0 : 100;
          rObj.yy2 = (obj.y3 > 0) ? ((obj.y2 - obj.y3) / obj.y3) * 100 : (obj.y2 === obj.y3) ? 0 : 100;
          return rObj;
        });
        return arr;
      }),
      map(data => this.serieTabloEC = data)
    ); 

    this.trProm$ = this.trapi.tableauSynthese(j.getDate(), j1.getDate(), m, y)
    .pipe(
      map(res => { return res.serie }),
      map(arr => {
        arr.map(obj => {
          var rObj = obj;
          rObj.dd = (obj.d1 > 0) ? ((obj.d - obj.d1) / obj.d1) * 100 : (obj.d === obj.d1) ? 0 : 100;
          rObj.mm = (obj.m1 > 0) ? ((obj.m - obj.m1) / obj.m1) * 100 : (obj.m === obj.m1) ? 0 : 100;
          rObj.mm1 = (obj.m2 > 0) ? ((obj.m1 - obj.m2) / obj.m2) * 100 : (obj.m1 === obj.m2) ? 0 : 100;
          rObj.mm2 = (obj.m3 > 0) ? ((obj.m2 - obj.m3) / obj.m3) * 100 : (obj.m2 === obj.m3) ? 0 : 100;
          rObj.yy = (obj.y1 > 0) ? ((obj.y - obj.y1) / obj.y1) * 100 : (obj.y === obj.y1) ? 0 : 100;
          rObj.yy1 = (obj.y2 > 0) ? ((obj.y1 - obj.y2) / obj.y2) * 100 : (obj.y1 === obj.y2) ? 0 : 100;
          rObj.yy2 = (obj.y3 > 0) ? ((obj.y2 - obj.y3) / obj.y3) * 100 : (obj.y2 === obj.y3) ? 0 : 100;
          return rObj;
        });
      return arr;
      }),
      map(data => this.serieTabloTR = data)
    );

    this.dateRefLib = new Date(this.dateRef).toLocaleString('fr-FR', {year: 'numeric', month: "2-digit", day: "2-digit"});
    this.compRefLib = new Date(this.compRef).toLocaleString('fr-FR', {year: 'numeric', month: "2-digit", day: "2-digit"});
    this.moisLib = new Date(this.dateRef).toLocaleString('fr-FR', {month: "long"});
    this.jourLib = new Date(this.dateRef).getDate();
    this.moisJourLib = new Date(this.compRef).toLocaleString('fr-FR', {month: "2-digit", day: "2-digit"});

  }

  initForm() {
    this.syntheseForm = new FormGroup(this.builder.group({
      dateRef: [this.dateRef, Validators.required],
      compRef: [this.compRef, Validators.required],
      dateComp: [this.dateComp]
    }).controls, {updateOn: 'change'});
  }

  onChangeDate(event: MatRadioChange) {
    var dt = this.syntheseForm.get("dateRef").value;
    var dte = new Date(dt);
    var cr = '';
    if(event.value === 'sameDay') {
      cr = this.getSameDayOfYear(dt, -1).toISOString().split('T')[0];
    }else{
      dte.setFullYear(dte.getFullYear() - 1);
      cr = new Date(dte).toISOString().split('T')[0];
    }
    this.dateComp = event.value;
    this.syntheseForm.setValue({dateRef: dt, compRef: cr, dateComp: event.value});
  }

  onChangeRef(event: string) {
    var dt = new Date(event);
    var dt1 = new Date(event);
    if(this.dateComp === 'sameDate') {
      dt1.setFullYear(dt.getFullYear() - 1);
    }else{
      dt1 = this.getSameDayOfYear(dt, -1);
    }

    this.syntheseForm.setValue({dateRef: new Date(dt).toISOString().split('T')[0], compRef: new Date(dt1).toISOString().split('T')[0], dateComp: this.dateComp});
  }

  onSubmitDate() {
    this.dateRef = this.syntheseForm.get("dateRef").value;
    this.compRef = this.syntheseForm.get("compRef").value;
    this.dateComp = this.syntheseForm.get("dateComp").value;
    this.initTablo(new Date(this.dateRef), new Date(this.compRef));
    this.jourReference = new Date(this.syntheseForm.get("dateRef").value).toLocaleString('fr-FR', {year: 'numeric', month: "long", day: "2-digit"});
  }

  getSameDayOfYear(date, yearsToAdd){
    date = typeof(date) === "string" ? new Date(date) : date;
    var retDate = new Date(date);
    retDate.setUTCFullYear(retDate.getUTCFullYear() + yearsToAdd);
    
    var diff = date.getUTCDay() - retDate.getUTCDay();
    if(date.getUTCDay() == 0)
      diff = 1;
    
    retDate.setUTCDate(retDate.getUTCDate() + diff);
    
    if(retDate.getUTCMonth() > date.getUTCMonth())
      retDate.setUTCDate(retDate.getUTCDate() - 7);
    if(retDate.getUTCMonth() < date.getUTCMonth())
      retDate.setUTCDate(retDate.getUTCDate() + 7);
    
    return retDate;
  }

  ngOnDestroy() {
    if(this.refEcomm) this.refEcomm.dispose();
    if(this.refTraff) this.refTraff.dispose();
    if(this.subscriptions.length > 0) this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
