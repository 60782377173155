import { NgModule } from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';

import { AuthGuardService } from './services/auth-guard.service';
import { AdminGuardService } from './services/admin-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SyntheseComponent } from './components/synthese/synthese.component';
import { HitparadeComponent } from './components/hitparade/hitparade.component';
import { HitparadesaisonComponent } from './components/hitparadesaison/hitparadesaison.component';
import { HitparaderetourComponent } from './components/hitparaderetour/hitparaderetour.component';
import { GeorepartitionComponent } from './components/georepartition/georepartition.component';
import { TranchedageComponent } from './components/tranchedage/tranchedage.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { StocksComponent } from './components/stocks/stocks.component';

const ROUTES:Routes = [
    // La route principale
    {
        path: '', component: DashboardComponent, pathMatch: 'full'
    },
    {
        path: 'dashboard', canActivate: [AuthGuardService], component: DashboardComponent
    },
    {
        path: 'synthese', canActivate: [AuthGuardService], component: SyntheseComponent
    },
    {
        path: 'hitparade/saisons', canActivate: [AuthGuardService], component: HitparadesaisonComponent
    },
    {
        path: 'hitparade/ventes', canActivate: [AuthGuardService], component: HitparadeComponent
    },
    {
        path: 'hitparade/retours', canActivate: [AuthGuardService], component: HitparaderetourComponent
    },
    {
        path: 'georepartition', canActivate: [AuthGuardService], component: GeorepartitionComponent
    },
    {
        path: 'tranchedage', canActivate: [AuthGuardService], component: TranchedageComponent
    },
    {
        path: 'stocks', canActivate: [AuthGuardService], component: StocksComponent
    },
    {
        path: 'profile', canActivate: [AuthGuardService], component: ProfileComponent
    },
    {
        path: 'administration', canActivate: [AdminGuardService], loadChildren: './modules/admin/admin.module#AdminModule'
    },
    {
        path: 'crm', canActivate: [AdminGuardService], loadChildren: './modules/crm/crm.module#CrmModule'
    },
    {
        path: 'traffic', canActivate: [AuthGuardService], loadChildren: './modules/traffic/traffic.module#TrafficModule'
    },
    {
        path: 'ecommerce', canActivate: [AuthGuardService], loadChildren: './modules/ecommerce/ecommerce.module#EcommerceModule'
    },
    // Toutes les routes introuvables
    {
        path: '**', component: NotFoundComponent
    }

];

@NgModule({
    imports: [
      RouterModule.forRoot(
        ROUTES,
        { enableTracing: false } // <-- debugging purposes only
      )
    ],
    exports: [
      RouterModule
    ]
  })
  export class AppRoutingModule {}